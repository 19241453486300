import React, { useEffect, useMemo } from "react";
import styled from "styled-components";
import { theme } from "../../../../utils/theme";
import { AppText, FlexDiv, NewAppButton, StatCard } from "../../../UI";

import RenderAssociateTitle from "../../../Pages/LeadDetail/RenderAssociateTitle";

import {
  chat,
  download,
  folder,
  mail,
  plain_phone_white,
  play_circle_blue,
  phone_incoming_blue,
  phone_outgoing_blue,
  message_incoming,
  message_outgoing,
  mail_incoming,
  mail_outgoing,
  check_thick,
  associated_white,
  user_white,
} from "../../../../images/NewDesign";

import moment from "moment";
import { useContext } from "react";
import { CallResultPill, DispositionReasonPill, PersonSpokeToPill } from "src/Components/UI/Pills";
import { CallContext, LeadCardContext, ModalContext, UserStatusContext } from "../../../../context";
import { formatCallDurationShortened, formatPhoneNumber } from "../../../../utils/format";
import { downloadResourceCors, LEAD_OWNERSHIP_STATUS, makeNextActionDisabledCheck } from "../../../../utils/misc";
import { PhoenixAppButton, PhoenixIcon } from "../../../UI/Phoenix";
import { loggedInUser } from "src/apollo/cache";
import { MixpanelActions } from "src/services/mixpanel";
import ReactTooltip from "react-tooltip";

const returnTooltipStatCard = ({
  primary_lead_id,
  associate_lead_ids,
  contact_id,
  isAssociatedLead,
  lead_activity_data,
}: {
  primary_lead_id: string;
  associate_lead_ids: string[];
  contact_id: string;
  isAssociatedLead: boolean;
  lead_activity_data: Partial<IRecentActivityItem>;
}) => {
  if (isAssociatedLead) {
    if (
      !!lead_activity_data &&
      !!lead_activity_data?.associate_child &&
      !associate_lead_ids?.includes(lead_activity_data?.associate_child?.id)
    ) {
      return "Disassociated lead";
    }
    return "Associated lead";
  }

  if (contact_id === primary_lead_id) {
    return "Primary lead";
  }

  if (associate_lead_ids?.includes(contact_id)) {
    return "Associated lead";
  }

  return "Disassociated lead";
};

interface IRecentActivityItem {
  id: string;
  title: string;
  type: string;
  conference_id: string | null;
  lead_id: string;
  twilio_action: string | null;
  computed_title: string;
  intent_type: string | null;
  call_completed_with: string | null;
  created_at: string;
  channel: string;
  computed_type: string;
  customer_number: string | null;
  communication_type: string | null;
  external_transfer_number: string | null;
  lead: {
    id: string;
    full_name: string;
    business_name: string;
    lead_ownership_status: string;
    rep_id: string;
    primary_phone_number?: string;
    next_intent_scheduled_or_unscheduled: {
      id?: string;
      type?: string;
    };
  };
  local_number: {
    number: string;
  } | null;
  related_disposition_reason: string;
  related_disposition: {
    id: string;
    label: string;
    computed_call_history_theme: {
      completed_with_theme: {
        label: string;
        bg_color: string;
        font_color: string;
      };
      call_result_theme: {
        label: string;
        bg_color: string;
        font_color: string;
      };
    };
  } | null;
  user: {
    id: string;
    first_name: string;
    last_name: string;
    profile_image: string | null;
    phone_number: string | null;
  };
  conference: {
    id: string;
    duration: number;
    recording_url_computed: string;
  } | null;
  email_item: {
    id: string;
    subject: string;
    body: string;
    html: string;
    from_email: string;
    to_email: string;
    reply_to_email: string;
    sender_label: string;
    isFromUser: boolean;
    isFromRep: boolean;
    opened_at?: string;
    clicked_at?: string;
  } | null;
  SMSItem: {
    id: string;
    text: string;
    sender_label: string;
    lead_number_used: string;
    isFromRep: boolean;
    isFromUser: boolean;
  } | null;
  notes: string | null;
  associate_child: {
    id: string;
    first_name: string;
    last_name: string;
    full_name: string;
    associate_parent_id: string;
  } | null;
}

const RecentActivityItem = ({
  item,
  primary_lead_id,
  associate_lead_ids,
  contact_id,
  setCurrentConferenceID = () => {},
  setShowSaveToFolderModal = () => {},
  showExtendedInfoSections,
  backgroundColor,
  showRepIcon,
  isAssociatedLead = false,
}: {
  item: Partial<IRecentActivityItem>;
  primary_lead_id: string;
  associate_lead_ids: string[];
  contact_id: string;
  setCurrentConferenceID?: (id: string) => void;
  setShowSaveToFolderModal?: (show: boolean) => void;
  showExtendedInfoSections: boolean;
  backgroundColor?: string;
  showRepIcon: boolean;
  isAssociatedLead: boolean;
}) => {
  const { setShowSMSAndEmailDetailsModal, setSMSAndEmailDetailsModalData } = useContext(ModalContext);

  const { nextActionOverride, setRepLeadSearch, setNextActionOverride } = useContext(CallContext);
  const { leadInNextDial, ableToOverideNextDial } = useContext(LeadCardContext);
  const { updateUserToIdle } = useContext(UserStatusContext);

  const repSentCommunication =
    (item.communication_type === "ExternalTransfer" ||
      item.communication_type === "Phone" ||
      item.communication_type === "SMS" ||
      item.communication_type === "Email") &&
    item.channel === "Outbound";

  const repReceivedCommunication =
    (item.communication_type === "Phone" || item.communication_type === "SMS" || item.communication_type === "Email") &&
    item.channel === "Inbound";

  const isOwnedByRep = item?.lead?.rep_id === loggedInUser()?.id;
  const canDialCustomers = loggedInUser()?.organization?.enable_rep_to_connect_customers;

  const inCallableStatus = [
    LEAD_OWNERSHIP_STATUS.Owned,
    LEAD_OWNERSHIP_STATUS.Assigned,
    ...(canDialCustomers ? [LEAD_OWNERSHIP_STATUS.Customer] : []),
  ].includes(item?.lead?.lead_ownership_status as LEAD_OWNERSHIP_STATUS);

  // needed for conditional tooltip rendering bugs
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [leadInNextDial, item]);

  const emailStatus = item?.email_item?.clicked_at
    ? "Link Clicked"
    : item?.email_item?.opened_at
    ? "Opened"
    : "Not Read";

  return (
    <HistoryItemDiv key={item.id} backgroundColor={backgroundColor} showRepIcon={showRepIcon}>
      <ReactTooltip
        id={`recent-activity-item-${item.id}`}
        multiline
        place="right"
        effect="solid"
        className="sib-tooltip"
        css={{
          maxWidth: 600,
          lineHeight: 1.4,
          textAlign: "center",
          fontFamily: theme.PRIMARY_FONT,
        }}
        backgroundColor={theme.PRIMARY800}
      />
      <FlexApartDiv style={{ marginBottom: "8px" }}>
        <div>
          {(() => {
            if (item.communication_type === "Phone") {
              return (
                <AppText
                  fontSize={14}
                  fontWeight={600}
                  // data-tip={(item.related_disposition?.label || item.related_disposition?.label) ?? undefined}
                  // data-for={
                  //   item.related_disposition?.label || item.related_disposition?.label
                  //     ? `recent-activity-item-${item.id}`
                  //     : undefined
                  // }
                >
                  <StyledLink
                    href={`/lead-detail/${primary_lead_id}`}
                    target="_blank"
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    {repSentCommunication ? "Outgoing Call" : "Incoming Call"}
                  </StyledLink>
                </AppText>
              );
            } else if (item.communication_type === "SMS") {
              return (
                <AppText
                  fontSize={14}
                  fontWeight={600}
                  onClick={() => {
                    localStorage.setItem("step_history", "2");
                    // localStorage.setItem("open_recent_sms", item?.SMSItem?.id ?? "");
                    window.open(`/lead-detail/${primary_lead_id}`, "_blank");
                  }}
                  style={{ cursor: "pointer", borderBottom: "none" }}
                >
                  {repSentCommunication ? "SMS Sent" : "SMS Received"}
                </AppText>
                // </Link>
              );
            } else if (item.communication_type === "Email") {
              return (
                <AppText
                  fontSize={14}
                  fontWeight={600}
                  onClick={() => {
                    localStorage.setItem(
                      "open_recent_activity",
                      JSON.stringify({ email_id: `${item?.email_item?.id}` }),
                    );
                    window.open(`/lead-detail/${primary_lead_id}?tab=emails`, "_blank");
                  }}
                  style={{ cursor: "pointer", borderBottom: "none" }}
                >
                  {repSentCommunication ? "Email Sent" : "Email Received"} {!!emailStatus ? `(${emailStatus})` : ""}
                </AppText>
              );
            } else if (item.communication_type === "ExternalTransfer") {
              return (
                <AppText fontSize={14} fontWeight={600}>
                  External Transfer
                </AppText>
              );
            } else {
              return (
                <AppText fontSize={14} fontWeight={600}>
                  {item.title ? item.title : item.type === "Off Call Note" ? "Off Call Note" : "NA"}
                </AppText>
              );
            }
          })()}
        </div>
        {(item.communication_type === "Phone" || item?.communication_type === "ExternalTransfer") && (
          <RightDateDiv>
            {(item.communication_type === "Phone" || item.communication_type === "ExternalTransfer") && (
              <DispositionsDiv>
                {item?.related_disposition?.computed_call_history_theme?.completed_with_theme?.label && (
                  <RepAndResultIcon
                    background={
                      item?.related_disposition?.computed_call_history_theme?.completed_with_theme?.bg_color ??
                      theme.PILL_LIGHT_GRAY
                    }
                    color={
                      item?.related_disposition?.computed_call_history_theme?.completed_with_theme?.font_color ??
                      "#000000"
                    }
                  >
                    {item?.related_disposition?.computed_call_history_theme?.completed_with_theme?.label ?? "NA"}
                  </RepAndResultIcon>
                )}

                {item?.related_disposition?.computed_call_history_theme?.call_result_theme?.label && (
                  <RepAndResultIcon
                    background={
                      item?.related_disposition?.computed_call_history_theme?.call_result_theme?.bg_color ??
                      theme.PILL_LIGHT_GRAY
                    }
                    color={
                      item?.related_disposition?.computed_call_history_theme?.call_result_theme?.font_color ??
                      theme.BLACK_COLOR
                    }
                  >
                    {item?.related_disposition?.computed_call_history_theme?.call_result_theme?.label ?? "NA"}
                  </RepAndResultIcon>
                )}
              </DispositionsDiv>
            )}
            {item.related_disposition_reason && (
              <DispositionReasonPill
                reason={item.related_disposition_reason ?? "N/A"}
                disposition={item?.related_disposition?.label || ""}
              />
            )}
          </RightDateDiv>
        )}
      </FlexApartDiv>
      <FlexDiv
        gap={8}
        align="center"
        style={{ marginBottom: "8px" }}
        // data-for={item?.lead?.full_name || item?.associate_child?.full_name ? "lead-card-tooltip" : undefined}
      >
        {item?.lead?.lead_ownership_status === "Customer" && (
          <FlexDiv direction="column" align="flex-start" gap={8}>
            <StatCard icon={check_thick} variant="primary" backgroundColor={theme.SUCCESS500} text="CURRENT CUSTOMER" />
          </FlexDiv>
        )}
        <AppText
          fontSize={12}
          fontWeight={600}
          style={{
            cursor: "pointer",
          }}
        >
          <StyledLink href={`/lead-detail/${primary_lead_id}`} target="_blank">
            {item?.lead?.business_name || "NA"}
            {" | "}
            {!item.associate_child ? item?.lead?.full_name || "NA" : item?.associate_child?.full_name || "NA"}
          </StyledLink>
        </AppText>
      </FlexDiv>

      <FlexApartDiv>
        <div>
          {/*  title and val */}
          <InnerRow>
            {item.communication_type === "Phone" ? (
              <CommWithIconDiv>
                <img src={phone_incoming_blue} alt="Phone" style={{ height: "16px" }} />
                <div>
                  <CommunicationTitleText>Recipient Number</CommunicationTitleText>
                  <WrappingText>
                    {item.channel === "Outbound"
                      ? formatPhoneNumber(item.customer_number)
                      : formatPhoneNumber(item?.user?.phone_number)}
                  </WrappingText>
                </div>
              </CommWithIconDiv>
            ) : item.communication_type === "Email" ? (
              <CommWithIconDiv>
                <PhoenixIcon svg={mail_incoming} variant="brand" alt="Email" size="small" />
                <div>
                  <CommunicationTitleText>Recipient Email</CommunicationTitleText>
                  <WrappingText>{item.email_item?.to_email ?? ""} </WrappingText>
                </div>
              </CommWithIconDiv>
            ) : item.communication_type === "Internal" ? (
              <CommWithIconDiv>
                <img src={plain_phone_white} alt="Phone" style={{ height: "16px" }} />
                <div>
                  <CommunicationTitleText>NA</CommunicationTitleText>
                  <WrappingText>NA</WrappingText>
                </div>
              </CommWithIconDiv>
            ) : item.communication_type === "SMS" ? (
              <CommWithIconDiv>
                <img src={chat} alt="SMS" style={{ height: "16px" }} />
                <div>
                  <CommunicationTitleText>Recipient Number</CommunicationTitleText>
                  <WrappingText>
                    {item?.SMSItem?.lead_number_used ? formatPhoneNumber(item?.SMSItem?.lead_number_used) : "NA"}
                  </WrappingText>
                </div>
              </CommWithIconDiv>
            ) : (
              item.communication_type === "ExternalTransfer" && (
                <CommWithIconDiv>
                  <img src={plain_phone_white} alt="Phone" style={{ height: "16px" }} />
                  <div>
                    <CommunicationTitleText>Transfer Number</CommunicationTitleText>
                    <WrappingText>
                      {!!item.external_transfer_number ? formatPhoneNumber(item?.external_transfer_number) : "NA"}
                    </WrappingText>
                  </div>
                </CommWithIconDiv>
              )
            )}
          </InnerRow>

          {/** title and val*/}
          <InnerRow>
            {item.communication_type === "Phone" ? (
              <CommWithIconDiv>
                <img src={phone_outgoing_blue} alt="Phone" style={{ height: "16px" }} />
                <div>
                  <CommunicationTitleText>Originating Number</CommunicationTitleText>
                  <WrappingText>
                    {/* check if the call was made using a local presence number */}
                    {!!item.local_number?.number
                      ? item.channel === "Outbound"
                        ? formatPhoneNumber(item?.local_number?.number)
                        : formatPhoneNumber(item?.customer_number)
                      : item?.user?.phone_number
                      ? item.channel === "Outbound"
                        ? formatPhoneNumber(item?.user?.phone_number)
                        : formatPhoneNumber(item?.customer_number)
                      : "NA"}
                  </WrappingText>
                </div>
              </CommWithIconDiv>
            ) : item.communication_type === "Email" ? (
              <CommWithIconDiv>
                <PhoenixIcon svg={mail_outgoing} variant="brand" alt="Email" size="small" />
                <div>
                  <CommunicationTitleText>Originating Email</CommunicationTitleText>
                  <WrappingText>{item.email_item?.from_email ?? "NA"} </WrappingText>
                </div>
              </CommWithIconDiv>
            ) : item.communication_type === "Internal" ? (
              <CommWithIconDiv>
                <img src={phone_outgoing_blue} alt="Phone" style={{ height: "16px" }} />
                <div>
                  <CommunicationTitleText>NA</CommunicationTitleText>
                  <WrappingText>NA</WrappingText>
                </div>
              </CommWithIconDiv>
            ) : item.communication_type === "SMS" ? (
              <CommWithIconDiv>
                <img src={chat} alt="SMS" style={{ height: "16px" }} />
                <div>
                  <CommunicationTitleText>Originating Number</CommunicationTitleText>

                  <WrappingText>
                    {item?.user?.phone_number ? formatPhoneNumber(item?.user?.phone_number) : "NA"}
                  </WrappingText>
                </div>
              </CommWithIconDiv>
            ) : (
              item.communication_type === "ExternalTransfer" && (
                <CommWithIconDiv>
                  <img src={phone_outgoing_blue} alt="Phone" style={{ height: "16px" }} />
                  <div>
                    <CommunicationTitleText>Originating Number</CommunicationTitleText>
                    <WrappingText>
                      {/* check if the call was made using a local presence number */}
                      {!!item.local_number?.number
                        ? item.channel === "Outbound"
                          ? formatPhoneNumber(item?.local_number?.number)
                          : formatPhoneNumber(item?.customer_number)
                        : item?.user?.phone_number
                        ? item.channel === "Outbound"
                          ? formatPhoneNumber(item?.user?.phone_number)
                          : formatPhoneNumber(item?.customer_number)
                        : "NA"}
                    </WrappingText>
                  </div>
                </CommWithIconDiv>
              )
            )}
          </InnerRow>
        </div>
        <RightDateDiv style={{ paddingTop: "8px" }}>
          <AppText fontSize={12} fontWeight={400}>
            {!!item.created_at ? moment(item.created_at).format("M/D/YYYY") : ""}{" "}
          </AppText>
          <AppText fontSize={12} fontWeight={400} color={theme.text.neutral.secondary}>
            {!!item.created_at ? moment(item.created_at).format("h:mm A") : ""}
          </AppText>
        </RightDateDiv>
      </FlexApartDiv>
      {showExtendedInfoSections && item.communication_type === "Email" && (
        <div
          style={{
            width: "max-content",
          }}
          onClick={() => {
            const currentItem = JSON.parse(JSON.stringify(item));
            currentItem.modal_primary_lead_id = primary_lead_id;
            currentItem.modal_associate_lead_ids = associate_lead_ids;
            currentItem.modal_contact_id = contact_id;
            currentItem.modal_is_associate_lead = isAssociatedLead;
            setSMSAndEmailDetailsModalData(currentItem);
            setShowSMSAndEmailDetailsModal(true);
          }}
        >
          <CommunicationText>{item?.email_item?.subject}</CommunicationText>
          <AppText variant="primary" fontWeight={600} fontSize={12} style={{ cursor: "pointer" }}>
            View Email
          </AppText>
        </div>
      )}
      {showExtendedInfoSections && item.communication_type === "SMS" && (
        <div
          style={{
            width: "max-content",
          }}
          onClick={() => {
            const currentItem = JSON.parse(JSON.stringify(item));
            currentItem.modal_primary_lead_id = primary_lead_id;
            currentItem.modal_associate_lead_ids = associate_lead_ids;
            currentItem.modal_contact_id = contact_id;
            currentItem.modal_is_associate_lead = isAssociatedLead;
            setSMSAndEmailDetailsModalData(currentItem);
            setShowSMSAndEmailDetailsModal(true);
          }}
        >
          <CommunicationText>{item?.SMSItem?.text}</CommunicationText>
          <AppText variant="primary" fontWeight={600} fontSize={12} style={{ cursor: "pointer" }}>
            View SMS
          </AppText>
        </div>
      )}
      {(item?.communication_type === "Phone" || item?.communication_type === "ExternalTransfer") &&
        !!item?.conference?.duration &&
        !!item?.conference?.recording_url_computed && (
          <InnerRow style={{ margin: "0px" }}>
            <StyledLink href={`/call-report/${item.conference?.id}`} target="_blank">
              <ListItemDuration style={{ cursor: "pointer" }}>
                <AppText fontWeight={400} fontSize={12}>
                  {`(${formatCallDurationShortened(item.conference.duration)})`}
                </AppText>
                <PhoenixIcon svg={play_circle_blue} variant="brand" alt="Play" size={16} />
              </ListItemDuration>
            </StyledLink>

            <PhoenixIcon
              style={{ marginLeft: "8px" }}
              svg={folder}
              variant="brand"
              alt="Save"
              size={16}
              data-tip="Save"
              data-for={`recent-activity-item-${item.id}`}
              pointer
              onClick={() => {
                setCurrentConferenceID(item.conference?.id || "");
                setShowSaveToFolderModal(true);
              }}
            />

            <PhoenixIcon
              style={{ marginLeft: "8px" }}
              svg={download}
              variant="brand"
              alt="Download"
              size={16}
              data-tip="Download"
              data-for={`recent-activity-item-${item.id}`}
              pointer
              onClick={() => downloadResourceCors(item?.conference?.recording_url_computed)}
            />
          </InnerRow>
        )}
      {!!item?.id && nextActionOverride?.intent_object?.lead?.id !== item?.id && !!item?.lead?.primary_phone_number && (
        <MakeNextActionDiv
          data-tip={
            makeNextActionDisabledCheck({
              inCallableStatus: inCallableStatus,
              currentLeadStatus: item?.lead?.lead_ownership_status as LEAD_OWNERSHIP_STATUS,
              isOwnedByRep: isOwnedByRep,
              isAssociatedLead: isAssociatedLead,
              nextDialOverrideBlocked: !ableToOverideNextDial,
              expandedState: true,
              leadIsInNextDial: item?.lead?.id === leadInNextDial,
            })?.tooltip
          }
          data-for={
            makeNextActionDisabledCheck({
              inCallableStatus: inCallableStatus,
              currentLeadStatus: item?.lead?.lead_ownership_status as LEAD_OWNERSHIP_STATUS,
              isOwnedByRep: isOwnedByRep,
              isAssociatedLead: isAssociatedLead,
              nextDialOverrideBlocked: !ableToOverideNextDial,
              expandedState: true,
              leadIsInNextDial: item?.lead?.id === leadInNextDial,
            })?.disabled
              ? `recent-activity-item-${item.id}`
              : undefined
          }
        >
          <MakeNextActionButton
            // variant={"brand"}
            buttonType="primary"
            disabled={
              makeNextActionDisabledCheck({
                inCallableStatus: inCallableStatus,
                currentLeadStatus: item?.lead?.lead_ownership_status as LEAD_OWNERSHIP_STATUS,
                isOwnedByRep: isOwnedByRep,
                isAssociatedLead: isAssociatedLead,
                nextDialOverrideBlocked: !ableToOverideNextDial,
                expandedState: true,
                leadIsInNextDial: item?.lead?.id === leadInNextDial,
              })?.disabled
            }
            onClick={() => {
              updateUserToIdle({ do_not_update_if_on_call: true });

              // Previous request by leadership to always have search panel clsoe on make next action
              // clear search
              setRepLeadSearch("");
              MixpanelActions.track("Recent Activity Item", {
                type: "",
                id: item?.lead?.id,
                source: "recentActivities",
              });

              console.log("intent of lead going into nextAction", item?.lead?.next_intent_scheduled_or_unscheduled);
              setNextActionOverride({
                intent_object: {
                  id: item?.lead?.next_intent_scheduled_or_unscheduled?.id ?? undefined,
                  lead: {
                    ...(item?.lead as any),
                    id: item?.lead?.id ?? "",
                    primary_phone_number: item?.lead?.primary_phone_number ?? "",
                  },
                  type: item?.lead?.next_intent_scheduled_or_unscheduled?.type,
                },
                parent: "recentActivities",
              });
              // resetInfiniteScroll();
            }}
            className="make-next-action-button"
            id={`make-next-action-activities-${item?.lead?.id}`}
          >
            MAKE NEXT ACTION
          </MakeNextActionButton>
        </MakeNextActionDiv>
      )}
    </HistoryItemDiv>
  );
};

const MakeNextActionDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 16px;
  max-width: 162px;
`;

const MakeNextActionButton = styled(PhoenixAppButton)`
  height: 40px;
  font-size: 10px;
  font-weight: 600;
`;

const DispositionsDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: flex-end;
`;

const CommWithIconDiv = styled.div`
  display: grid;
  grid-template-columns: 16px 1fr;
  gap: 12px;
`;

const FlexApartDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 4px;
`;

const CommunicationText = styled(AppText)`
  font-size: 12px;
  font-weight: 400;
  max-height: 40px;
  max-width: 400px;
  white-space: pre-wrap;
  word-wrap: wrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-top: 8px;
`;

const RightDateDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-end;
  justify-content: flex-end;
`;

const WrappingText = styled(AppText)`
  word-break: break-all;
  max-width: 200px;
  font-weight: 400;
  font-size: 12px;
`;

const ListItemDuration = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const StyledLink = styled.a`
  text-decoration: none;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    color: inherit;
  }
`;

const HistoryItemDiv = styled.div<{ backgroundColor: string | undefined; showRepIcon: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: ${(props) => props.backgroundColor ?? theme.WHITE_COLOR};
  padding: 16px;
  border-bottom: 1px solid ${theme.border.neutral.secondary};
`;

const InnerRow = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 8px 0px;
`;

interface RepAndResultIcon {
  background?: string;
  color?: string;
}
const RepAndResultIcon = styled.div<RepAndResultIcon>`
  // font
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 14px;
  text-transform: uppercase;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 10px;
  margin-left: 4px;
  margin-bottom: 4px;

  background-color: ${(props) => {
    return props.background;
  }};

  color: ${(props) => {
    return props.color;
  }};
  // never wrap
  white-space: nowrap;
`;

const CommunicationTitleText = styled(AppText)`
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
`;

export { RecentActivityItem };
