import styled from "styled-components";
import GreenCheck from "../../../images/icons-ic-notifications-accept.svg";
import { FaCaretDown, FaCaretUp, FaPlayCircle } from "react-icons/fa";
import * as Sentry from "@sentry/react";
import * as React from "react";
import { theme } from "../../../utils/theme";
import { gql, useQuery } from "@apollo/client";
import { AppErrorText, AppText, Loading } from "../../UI";
import { useState, useContext, useEffect, useMemo } from "react";
import { CallReportItem } from "../../Smart/CallReportItem";
import { GridFilterContext } from "../../../context";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import { iconGreenCheck } from "../../../images/";
import { formatBusinessName, formatCallDuration, formatImportDate } from "../../../utils/format";
import { appToast } from "../../../utils/toast";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";
import { AppTable, TableRow, TableTD } from "../../UI/AppTable";
import { isUserRep, loggedInUser } from "./../../../apollo/cache";
const FETCH_EVENT_LIST = gql`
  query fetchEventList($dashboard_detail_args: EventDetailArgument!) {
    fetchEventListV2(dashboard_detail_args: $dashboard_detail_args) {
      id
      lead {
        id
        business_name
        first_name
        last_name
        industry
        sub_industry
        lead_source
        # dials
        set
        held
        close
      }
      schedule_item {
        id
        start_time
        resulting_activity {
          id
          related_disposition {
            id
            label
          }
        }
        lead_activity {
          id
          created_at
          communication_type
          stat_item_aggregation {
            days_set_out
          }
          sale_cycle {
            cycle_num
            lead_id
            set
            hold
            close
            num_dials
            set_user {
              id
              full_name
            }
            close_user {
              id
              full_name
            }
          }
        }
      }
      lead_id
      organization_id
      ranking
      show_during_scheduled_event_duration
      type
      anytime_before
      anytime_after
      anytime_day
      anytime_day_upperbound
      anytime_tz
      associated_action
      is_scheduled_item
      label
      type_label
      event_type_label
      current_sequence_step
      prepare_raw
      general_time_start_date
      general_time_end_date
      prev_dials_in_sales_cycle
      prepare
      # schedule_item
      schedule_item_id
      user {
        id
        first_name
        last_name
        full_name
      }
      user_id
      created_at
      updated_at
    }
  }
`;

const FETCH_USER_CALL_REPORT_EVENT_COLUMNS = gql`
  query fetchUserCallReportEventColumns {
    fetchUser {
      id
      visible_call_report_event_columns_computed
    }
  }
`;

interface CallReportProps {
  userID?: string;
  teamID?: string;
  siteID?: string;
  leadID?: string;
  orgID?: string;
  computedID?: string;
  metric?: string;
  gridFilter: boolean;
  callReportPage?: boolean;
}

const EventsListTableV2: React.FC<CallReportProps> = (props) => {
  const [sortAscending, setSortAscending] = useState(false);
  const {
    dateStart,
    dateEnd,
    dateLabel,
    repFilter,
    products,
    channel,
    gridFilter,
    leadFilterArgs,
    repFilterArgs,
    dateFilterArgs,
  } = useContext(GridFilterContext);

  const [reportsList, setReportsList] = useState<any[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [skip, setSkip] = useState(0);
  const [hasToBeRefetched, setHasToBeRefetched] = useState(false);
  const [orderBy, setOrderBy] = useState("EventTime");

  const takeNumber = 7;

  const calculatePosition = () => {
    if (!!props?.leadID) {
      return "Lead";
    }
    if (!!props?.userID) {
      return "User";
    }
    if (!!props?.siteID) {
      return "Site";
    }
    if (!!props?.teamID) {
      return "Team";
    }
    if (!!props?.orgID) {
      return "Organization";
    }
  };

  const handleColHeaderClick = (metric: string) => {
    if (orderBy === metric) {
      setSortAscending(!sortAscending);
    } else {
      setOrderBy(metric);
    }
  };

  const { data: dataColumns, loading: loadingColumns, error: errorColumns } = useQuery(
    FETCH_USER_CALL_REPORT_EVENT_COLUMNS,
    {
      fetchPolicy: "network-only",
      onError({ message }) {
        // Sentry.captureEvent({
        //   message: `fetchUser for Call Report Columns GraphQL Error: ${message}`,
        // });
        console.log(`Error in fetchUser for Call Report Columns: `, message);
        appToast(message);
      },
    },
  );

  const { data: listData, loading: listLoading, error: listError, called } = useQuery(FETCH_EVENT_LIST, {
    variables: {
      dashboard_detail_args: {
        computed_id: !!props.computedID ? props.computedID : undefined,
        position: calculatePosition(),
        metric: !!props.metric ? props.metric : undefined,
        skip: skip,
        take: takeNumber,
        order_by: orderBy,
        desc: !sortAscending,
        date_filter: dateFilterArgs,
        rep_filter: repFilterArgs,
        lead_filter: leadFilterArgs,
      },
    },
    fetchPolicy: "network-only",
    onCompleted() {
      const newList = [...reportsList, ...listData.fetchEventListV2].reduce(
        (acc, cv) => (acc?.map((a: any) => a?.id)?.includes(cv?.id) ? acc : [...acc, cv]),
        [],
      );
      setReportsList(newList);
      // setReportsList(reportsList.concat(listData.fetchEventList));
      if (listData.fetchEventListV2.length < takeNumber) setHasMore(false);
      else setHasMore(true);
    },
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const handleShowMore = () => {
    setSkip(skip + takeNumber);
    // refetch();
  };

  useEffect(() => {
    if (!called) {
      return;
    }
    setReportsList([]);
    setSkip(0);
    setHasMore(true);
  }, [
    props.gridFilter,
    props.leadID,
    props.metric,
    props.orgID,
    props.teamID,
    props.userID,
    channel,
    products,
    dateStart,
    dateEnd,
    sortAscending,
  ]);

  const visibleColumns = useMemo(() => {
    return dataColumns?.fetchUser?.visible_call_report_event_columns_computed ?? [];
  }, [dataColumns]);

  if (listError)
    return (
      <ListContainer callReportPage={props.callReportPage}>
        <AppErrorText>Error Loading Items</AppErrorText>
        <AppErrorText>{listError.message}</AppErrorText>
      </ListContainer>
    );

  // If no call reports available.
  if (!listLoading && reportsList.length === 0 && listData.fetchEventListV2 === 0)
    return (
      <ListContainer
        style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        callReportPage={props.callReportPage}
      >
        <AppText>No reports to display.</AppText>
      </ListContainer>
    );

  const RenderTableHeaderFor = (metric: string) => {
    const METRIC_LABELS = {
      CallTime: "Time of Call",
      LeadName: "Contact Name",
      BusinessName: "Business Name",
      SetByUser: "Sales Rep Who Set Demo",
      ClosedByUser: "Sales Rep Who made Sale",
      RepName: "Sales Rep",
      CallResult: "Call Result",
      AssociatedAction: "Call Result",
      EventTime: "Time of Event",
      EventType: "Event Type",
      Industry: "Industry",
      SubIndustry: "Sub-Industry",
      LeadSource: "Lead Source",
      // Revenue: "Revenue",
      // RevenueValue: "Revenue Value",
      // RecurringRevenue: "Recurring Revenue",
      DialsInCurrentSalesCycle: "Dials: Current Sales Cycle",
      Set: "Set",
      Held: "Held",
      Close: "Closed",
      ScheduleItemCallResult: "Call Result",
      DaysSetOut: "Days Set Out",
    };

    //@ts-ignore
    let label = METRIC_LABELS[metric];
    let style: any = { whiteSpace: "nowrap" };

    switch (metric) {
      case "CallResult":
        if (props.metric && ["Pipeline", "ClosingOpportunitiesPipeline"].includes(props.metric)) {
          label = "Associated Action";
        }
        break;
      case "AssociatedAction":
        if (props.metric && ["Pipeline", "ClosingOpportunitiesPipeline"].includes(props.metric)) {
          label = "Associated Action";
        }
        break;
    }

    return {
      label: label,
      onClick: () => handleColHeaderClick(metric),
      isAscending: sortAscending,
      showArrow: orderBy === metric,
      style: metric === "DaysSetOut" ? { ...style, minWidth: 100 + 24 } : style,
    };
  };

  const numberOfAdjustableColumns = visibleColumns
    .slice()
    .filter((item: string) => !["Set", "Held", "Close"].includes(item)).length;

  const RenderTableDataFor = (metric: string, item: any) => {
    switch (metric) {
      case "CallTime":
        return (
          <TableTD>
            <ListItemText>
              {item?.schedule_item?.lead_activity?.created_at
                ? formatImportDate(item?.schedule_item?.lead_activity?.created_at)
                : `-`}
            </ListItemText>
          </TableTD>
        );
      case "LeadName":
        return (
          <TableTD>
            <ListItemText>{`${item.lead?.first_name ?? ""} ${item.lead?.last_name ?? ""}`}</ListItemText>
          </TableTD>
        );
      case "EventType":
        return (
          <TableTD>
            <ListItemText>{`${item.event_type_label ?? "-"}`}</ListItemText>
          </TableTD>
        );
      case "BusinessName":
        return (
          <TableTD
            style={{ cursor: "pointer" }}
            onClick={() => window.open(`/lead-detail/${item?.lead?.id ?? ""}`, "_blank", "noreferrer")}
          >
            <WrapAnchor target="_blank" rel="noopener noreferrer" href={`/lead-detail/${item?.lead?.id ?? ""}`}>
              <ListItemText>{formatBusinessName(item.lead?.business_name)}</ListItemText>
            </WrapAnchor>
          </TableTD>
        );
      case "SetByUser":
        return (
          <TableTD
            style={{ minWidth: "76px", cursor: "pointer" }}
            onClick={() => window.open(`/lead-detail/${item?.lead?.id ?? ""}`, "_blank", "noreferrer")}
          >
            <WrapAnchor target="_blank" rel="noopener noreferrer" href={`/lead-detail/${item?.lead?.id ?? ""}`}>
              <ListItemText>{item.lead?.most_recent_sale_cycle?.set_user?.full_name ?? ""}</ListItemText>
            </WrapAnchor>
          </TableTD>
        );
      case "ClosedByUser":
        return (
          <TableTD
            style={{ minWidth: "76px", cursor: "pointer" }}
            onClick={() => window.open(`/lead-detail/${item?.lead?.id ?? ""}`, "_blank", "noreferrer")}
          >
            <WrapAnchor target="_blank" rel="noopener noreferrer" href={`/lead-detail/${item?.lead?.id ?? ""}`}>
              <ListItemText>{item.lead?.most_recent_sale_cycle?.close_user?.full_name ?? ""}</ListItemText>
            </WrapAnchor>
          </TableTD>
        );
      case "RepName":
        return (
          <TableTD>
            <ListItemText>{`${item.user?.first_name ?? ""} ${item.user?.last_name ?? ""}`}</ListItemText>
          </TableTD>
        );
      case "CallResult":
        return (
          <TableTD>
            <ListItemText>
              {props.metric && ["Pipeline", "ClosingOpportunitiesPipeline"].includes(props.metric)
                ? item.related_disposition?.associated_action ?? "No Action"
                : item.related_disposition?.label ?? "No Result"}
            </ListItemText>
          </TableTD>
        );
      case "EventTime":
        return (
          <TableTD>
            <ListItemText>
              {!!item.schedule_item?.start_time ? formatImportDate(item.schedule_item?.start_time) : `-`}
            </ListItemText>
          </TableTD>
        );
      case "Industry":
        return (
          <TableTD>
            <ListItemText>{`${item.lead?.industry ?? ""} `}</ListItemText>
          </TableTD>
        );
      case "SubIndustry":
        return (
          <TableTD>
            <ListItemText>{`${item.lead?.sub_industry ?? ""} `}</ListItemText>
          </TableTD>
        );
      case "LeadSource":
        return (
          <TableTD>
            <ListItemText>{`${item.lead?.lead_source ?? ""}`}</ListItemText>
          </TableTD>
        );
      case "Set":
        return (
          <TableTD style={{ width: "40px", minWidth: "40px" }}>
            <ListItemIcon>
              {item?.schedule_item?.lead_activity?.sale_cycle?.set ? <img src={GreenCheck} height="10px" /> : "-"}
            </ListItemIcon>
          </TableTD>
        );
      case "Held":
        return (
          <TableTD style={{ width: "40px", minWidth: "40px" }}>
            <ListItemIcon>
              {item?.schedule_item?.lead_activity?.sale_cycle?.hold ? <img src={GreenCheck} height="10px" /> : "-"}
            </ListItemIcon>
          </TableTD>
        );
      case "Close":
        return (
          <TableTD style={{ width: "40px", minWidth: "40px" }}>
            <ListItemIcon>
              {item?.schedule_item?.lead_activity?.sale_cycle?.close ? <img src={GreenCheck} height="10px" /> : "-"}
            </ListItemIcon>
          </TableTD>
        );
      case "ScheduleItemCallResult":
        return (
          <TableTD style={{ width: "40px", minWidth: "40px" }}>
            <ListItemText>{item?.schedule_item?.resulting_activity?.related_disposition?.label ?? ""}</ListItemText>
          </TableTD>
        );
      case "DaysSetOut":
        return (
          <TableTD style={{ width: "40px", minWidth: "40px" }}>
            {typeof item?.schedule_item?.lead_activity?.stat_item_aggregation?.days_set_out === "number" &&
              `${item?.schedule_item?.lead_activity?.stat_item_aggregation?.days_set_out} days`}
          </TableTD>
        );
      case "DialsInCurrentSalesCycle":
        return (
          <TableTD>
            <ListItemText>
              {(typeof item?.schedule_item?.lead_activity?.sale_cycle?.num_dials === "number" &&
                `${item?.schedule_item?.lead_activity?.sale_cycle?.num_dials}`) ||
                (typeof item?.prev_dials_in_sales_cycle === "number" && `${item?.prev_dials_in_sales_cycle}`)}
            </ListItemText>
          </TableTD>
        );
      default:
        return <></>;
    }
  };
  return (
    <>
      {props.callReportPage && <TitleText>Call History</TitleText>}
      <ListContainer callReportPage={props.callReportPage} id="call-report-list">
        <InfiniteScroll
          dataLength={reportsList.length}
          next={handleShowMore}
          hasMore={hasMore}
          loader={<Loading />}
          scrollableTarget="call-report-list"
        >
          <AppTable
            columns={
              !listLoading
                ? [
                    ...visibleColumns
                      ?.map((metric: string) => RenderTableHeaderFor(metric))
                      .filter((obj: any) => !!obj.label),
                  ]
                : []
            }
            headerStyle={{ fontSize: 10 }}
          >
            {!!reportsList.length &&
              reportsList.slice()?.map((item: any, index: number) => {
                return (
                  <TableRow key={`index${index}-conference${item.conference?.id}`}>
                    {visibleColumns?.map((metric: string) => RenderTableDataFor(metric, item))}
                  </TableRow>
                );
              })}
          </AppTable>
        </InfiniteScroll>
      </ListContainer>
    </>
  );
};

const TableContainerDiv = styled.div`
  position: relative;
  /* margin-top: 12px; */
  height: 299px;
  overflow: auto;
  /* overflow: hidden; */
`;

const WrapAnchor = styled.a`
  max-width: 100px;
  text-overflow: ellipsis;
`;

const ListItemText = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 10px;
  padding-right: 15px;
`;

const ListItemIcon = styled.div`
  place-self: center;
  font-size: 10px;
`;

const ListItemDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 35px 35px 35px;
  align-items: center;
  height: 40px;
  border-bottom: solid 1px ${theme.NEUTRAL100};
`;

const TitleText = styled(AppText)`
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  color: ${theme.NEUTRAL500};
  margin-top: 14px;
  margin-bottom: 5px;
`;

const HeaderDiv = styled.div`
  display: grid;
  position: sticky;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 35px 35px 35px;
  align-items: center;
  justify-items: start;
  margin-top: 14px;
  height: 30px;
  top: 0px;
  background-color: ${theme.WHITE_COLOR};
`;

interface ClickableProp {
  clickable?: boolean;
}

const HeaderText = styled(AppText)<ClickableProp>`
  font-size: 8px;
  font-weight: 500;
  border-bottom: none;
  cursor: ${(props) => (props.clickable ? "pointer" : "text")};
`;

const HeaderTextCenter = styled(AppText)<ClickableProp>`
  justify-self: center;
  font-size: 8px;
  font-weight: 500;
  border-bottom: none;
  cursor: ${(props) => (props.clickable ? "pointer" : "text")};
`;

interface CallReportPageProps {
  callReportPage?: boolean;
}

const ListContainer = styled.div<CallReportPageProps>`
  width: ${(props) => (props.callReportPage ? "947px" : "100%")};
  height: 299px;
  /* padding: 16px; */
  margin-top: 16px;
  margin-bottom: 16px;
  border-radius: ${(props) => (props.callReportPage ? "3px" : "16px")};
  /* box-shadow: 0 4px 8px 0 rgba(224, 224, 224, 0.5); */
  background-color: ${theme.WHITE_COLOR};

  overflow: auto;
  /* padding: 0px 25px 0px 25px; */
`;

const CallListTH = styled.th`
  position: sticky !important;
  inset-block-start: 0;
`;

interface TDProps {
  colNum: number;
  width?: number;
}

const TableData = styled.td<TDProps>`
  display: table-cell;
  /* overflow: hidden; */
  height: 40px;
  border-bottom: solid 1px ${theme.NEUTRAL100};
  table-layout: auto;
  width: ${(props) => (!!props.width ? `${props.width}px` : `calc((100%) / ${props.colNum})`)};
  max-width: 250px;
`;

const CallListTHead = styled.thead`
  position: sticky !important;
  inset-block-start: 0;
  top: 0px;
`;

const FlexDiv = styled.div`
  display: flex;
`;

interface ListItemDurationProps {
  display: boolean;
}

const ListItemDuration = styled.div<ListItemDurationProps>`
  border: solid 0.7px ${theme.PRIMARY500};
  border-radius: 2.1px;
  width: 74px;
  height: 17px;
  font-size: 10px;
  place-self: center;
  display: ${(props) => (props.display ? "flex" : "none")};
  margin-left: 12px;
`;

const MainTable = styled.table`
  max-width: 100%;
  position: relative;
  table-layout: fixed;
  overflow-x: hidden;
`;

const StyledLink = styled(Link)`
  text-decoration: none;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    color: inherit;
  }
`;

const CenterDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconDiv = styled.div`
  display: flex;
  /* flex-direction: column; */
  justify-content: flex-end;
  align-items: center;
  font-size: 8px;
  line-height: 13px;
  color: ${theme.TERTIARY500};
  margin-left: 8px;
  /* cursor: pointer; */
`;

export { EventsListTableV2 };
