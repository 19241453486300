import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { FieldArray, Formik, FormikProps } from "formik";
import moment from "moment";
import * as React from "react";
import { useContext, useEffect, useMemo, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { IoMdClose } from "react-icons/io";
import styled from "styled-components";
import * as Yup from "yup";
import { MONTHLY_SCHEDULE, ScheduleTimeSelectComponentV3 } from "../../../Components/Segments/CallSegments/";
import { currentCallState, loggedInUser } from "../../../apollo/cache";
import { CallContext, LeadCardContext } from "../../../context/";
import "../../../utils/react-big-calendar.css";
import { theme } from "../../../utils/theme";
import { AppButton, AppErrorText, AppIconButton, AppText } from "../../UI";
import { CustomToastComponent } from "./CustomToast";

import Switch from "react-switch";
import { timezone_list_items } from "../../../static/";
import { appToast, customToast, errorToast, successToast } from "../../../utils/toast";
import { InputField } from "../../Field/InputField";

import { useFlags } from "launchdarkly-react-client-sdk";
import { FiTrash2 } from "react-icons/fi";
import { PhoenixAppButton } from "src/Components/UI/Phoenix";
import { SHOW_NEXT_ACTION } from "src/apollo/query";
import { MeetingType } from "src/types";
import { SelectRepForBooking } from "../../../Components/Segments/CallSegments/";
import { calendarInviteSent, checkIcon, iconDesktop, iconTrashNew } from "../../../images";
import { validateIfEmailAddress } from "../../../utils/misc";
import { PhoenixStyledTooltip } from "../../Dumb/PhoenixStyledTooltip";
import { FormAreaInputField } from "../../Field";
import { PhoenixInputField } from "../../Field/Phoenix";
import { NewAppButton } from "../../UI/NewAppButton";
import { SkeletonBlock } from "../../UI/SkeletonBlock";
import { ProfileImageWithText } from "../SettingSegments/ProfileImageWithText";
import CalendarInviteStep from "./CalendarInviteStep";

interface DisappearingDivProps {
  user_id?: string;
  selectedDataID?: any;
  selectedDataTime?: any;
  selectedDataLeadID?: string;
  setCurrentTask?: (currentTask: string) => void;
  id?: string;
  time?: string;
  phone_number?: string;
  title?: string;
  type?: string;
  eventType: string;
  lead_id: string;
  lead_primary_email?: string;
  lead_full_name?: string;
  fromNonCallActivity?: boolean;
  closeModal?: () => void;
  dispositionType?: string;
  intent_id?: string;
  backToActionButtons: () => void;
  disableNoContectForDemo?: boolean;
  selectedDisposition?: string;
  selectedDispositionReason?: string;
  dispositionRequiresReason?: boolean;
  setAvailableRepsForBooking?: (reps: string[]) => void;
  startDemoRequiredFields: () => void;
  revertDemoRequiredFields: () => void;
}

const CREATE_SCHEDULED_EVENT = gql`
  mutation createScheduledEvent(
    $start_time: DateTime!
    $lead_id: String!
    $type: IntentType!
    $disposition_type: DISPOSITIONTYPE
    $person_spoke_to: CALLRESULT
    $intent_id: String
    $call: Boolean
    $disposition_id: String
    $action: String
    $primary_email: String
    $user_id: String
    $joinMe_meeting: Boolean
    $calendarInvite: Boolean
    $event_title: String
    $event_description: String
    $guest_list: [String]
    $joinMe_id: String
    $old_schedule_item_id: String
    $from_custom_call_queue: Boolean
    $timezone: String
    $call_sid: String
    $disposition_reason: String
    $meeting_type: MeetingType
  ) {
    createScheduledEvent(
      start_time: $start_time
      lead_id: $lead_id
      type: $type
      disposition_type: $disposition_type
      person_spoke_to: $person_spoke_to
      intent_id: $intent_id
      call: $call
      disposition_id: $disposition_id
      action: $action
      primary_email: $primary_email
      user_id: $user_id
      joinMe_meeting: $joinMe_meeting
      calendarInvite: $calendarInvite
      event_title: $event_title
      event_description: $event_description
      guest_list: $guest_list
      joinMe_id: $joinMe_id
      old_schedule_item_id: $old_schedule_item_id
      from_custom_call_queue: $from_custom_call_queue
      timezone: $timezone
      call_sid: $call_sid
      disposition_reason: $disposition_reason
      meeting_type: $meeting_type
    ) {
      id
      start_time
      end_time
      lead_activity {
        id
        related_disposition {
          id
          completed_text
          completed_image
          completed_tip
        }
      }
      join_me_item {
        id
        viewer_url
        meeting_id
      }
      google_space_sellfire_id
      google_space_info {
        id
        meeting_url
        meeting_code
      }
      zoom_meeting_sellfire_id
      zoom_meeting_info {
        id
        meeting_url
        meeting_id
      }
      lead_intent {
        id
        lead {
          id
          first_name
          last_name
          associate_children {
            id
          }
        }
        organization {
          id
          name
        }
        type
        show_during_scheduled_event_duration
        ranking
        prepare_raw
        show_action_buttons
        user {
          id
          email
        }
      }
    }
  }
`;

const CREATE_GENERAL_TIME_SCHEDULED_EVENT = gql`
  mutation createGeneralTimeScheduledEvent(
    $lead_id: String!
    $day: DateTime
    $week_start: DateTime
    $week_end: DateTime
    $month: DateTime
    $anytime_after: DateTime
    $anytime_before: DateTime
    $timezone: String
    $user_id: String
    $intent_id: String
    $disposition_id: String
    $person_spoke_to: CALLRESULT
    $call: Boolean
    $action: String
    $from_custom_call_queue: Boolean
    $call_sid: String
    $disposition_reason: String
  ) {
    createGeneralTimeScheduledEvent(
      lead_id: $lead_id
      day: $day
      week_start: $week_start
      week_end: $week_end
      month: $month
      anytime_after: $anytime_after
      anytime_before: $anytime_before
      timezone: $timezone
      user_id: $user_id
      intent_id: $intent_id
      disposition_id: $disposition_id
      person_spoke_to: $person_spoke_to
      call: $call
      action: $action
      from_custom_call_queue: $from_custom_call_queue
      call_sid: $call_sid
      disposition_reason: $disposition_reason
    ) {
      id
      lead {
        id
        last_lead_activity {
          id
          related_disposition {
            id
            completed_text
            completed_image
            completed_tip
          }
        }
      }
    }
  }
`;

const FETCH_ORGANIZATION = gql`
  query fetchOrganization {
    fetchOrganization {
      id
      timezone
    }
  }
`;

const FETCH_USER_TIMEZONE = gql`
  query fetchUser {
    fetchUser {
      id
      timezone
    }
  }
`;

const CREATE_JOIN_ME = gql`
  mutation createJoinMe($start: DateTime!, $end: DateTime!) {
    createJoinMe(start: $start, end: $end) {
      id
      viewer_url
      meeting_id
    }
  }
`;

const REMOVE_JOIN_ME_ITEM = gql`
  mutation removeJoinMeItem($joinME_id: String!) {
    removeJoinMeItem(joinME_id: $joinME_id) {
      id
      viewer_url
      meeting_id
    }
  }
`;

const GET_GLOBAL_SCHEDULE = gql`
  {
    fetchOrganization {
      demo_duration
      callback_duration
      decision_call_duration
    }
  }
`;

interface CheckIntegrationStatusExpectedResponse {
  checkIntegrationStatus: {
    [key: string]: boolean;
  };
}
const CHECK_INTEGRATION_STATUS = gql`
  query checkIntegrationStatus {
    checkIntegrationStatus
  }
`;

const FETCH_ORG_FUTURE_BOOKING_SETTINGS = gql`
  query FetchOrgFutureBookingSetting {
    fetchOrgFutureBookingSetting {
      id
      transfer_type
    }
  }
`;

interface CheckUserGoogleStatusExpectedResponse {
  checkUserGoogleStatus: {
    google_status: "active" | "inactive";
  };
}

const CHECK_USER_GOOGLE_STATUS = gql`
  query CheckUserGoogleStatus($user_id: String!) {
    checkUserGoogleStatus(user_id: $user_id)
  }
`;

interface CheckUserZoomStatusExpectedResponse {
  checkUserZoomStatus: {
    zoom_status: "active" | "inactive";
  };
}
const CHECK_USER_ZOOM_STATUS = gql`
  query CheckUserZoomStatus($user_id: String!) {
    checkUserZoomStatus(user_id: $user_id)
  }
`;

interface MyFormikProps {
  primary_email: any;
  id: string;
  time: string;
  specific_time?: string;
  general_time?: string;
  timezone: string;
  day: string;
  week_start: string;
  week_end: string;
  month: string;
  anytime_after: string;
  anytime_before: string;
  /**
   * Only set if meeting is being booked for a different rep
   */
  rep_id?: string;
  guest_list: string[];
  calendar_invite: boolean;
  event_title: string;
  event_description: string;
  joinMe_id: string;
  edit_event: boolean;
  available_reps_for_booking: string[];
  opsiq_suggested_rep?: string;
  selected_rep?: string;
  meeting_type?: MeetingType;
}

function eventStyleGetter(event: any, start: any, end: any, isSelected: any) {
  const style = {
    backgroundColor: event.color,
    borderRadius: "50%",
    color: theme.WHITE_COLOR,
    border: "0px",
    display: "block",
    maxHeight: "17px",
    minHeight: "17px",
    maxWidth: "17px",
    margin: "auto auto",
    paddin: "0px",
    fontSize: "11px",
    lineHeight: "12px",
  };
  return {
    style: style,
  };
}

function switchLabel(eventType: string, fromNonCallActivity?: boolean) {
  if (fromNonCallActivity) {
    return !!eventType ? `SET ${eventType.toUpperCase()}` : "SET ACTION";
  }
  return !!eventType ? `CONFIRM ${eventType.toUpperCase()}` : "CONFIRM EVENT";
}

const CreateScheduledEventComponentV3: React.FC<DisappearingDivProps> = ({
  user_id,
  selectedDataID,
  selectedDataTime,
  selectedDataLeadID,
  eventType,
  lead_primary_email,
  lead_id,
  fromNonCallActivity,
  closeModal,
  backToActionButtons,
  disableNoContectForDemo = false,
  selectedDisposition,
  selectedDispositionReason,
  dispositionRequiresReason,
  startDemoRequiredFields,
  revertDemoRequiredFields,
  ...props
}) => {
  const { data: orgData } = useQuery(SHOW_NEXT_ACTION);

  const formikRef = React.useRef<FormikProps<MyFormikProps>>(null);
  const { logDispositionReason, zoomIntegration, googleMeetIntegration } = useFlags();
  const { data: dataStatus } = useQuery<CheckIntegrationStatusExpectedResponse>(CHECK_INTEGRATION_STATUS);
  const zoomIsEnabledOnOrg = dataStatus?.checkIntegrationStatus["Zoom"] ?? false;
  const googleMeetIsEnabledOnOrg = dataStatus?.checkIntegrationStatus["Google"] ?? false;

  const {
    data: dataOrgFutureBookingSettings,
    error: errorOrgFutureBookingSettings,
    loading: loadingOrgFutureBookingSettings,
    refetch: refetchOrgLiveTransferSettings,
  } = useQuery(FETCH_ORG_FUTURE_BOOKING_SETTINGS, {
    fetchPolicy: "cache-and-network",
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
      appToast("Error fetching live transfer settings");
    },
  });

  const [showCalendar, setShowCalendar] = useState(false);
  const [specificOptionStep, setSpecificOptionStep] = useState(0);
  const [yearMonth, setYearMonth] = useState("");
  const [dateValue, setDateValue] = useState(moment(selectedDataTime).format("YYYY-MM-DD hh:mm A"));

  const [dispositionStep, setDispositionStep] = useState(1);
  const [joinMeMeeting, setJoinMeMeeting] = useState({} as any);
  const [oldScheduledID, setOldScheduledID] = useState("");

  const [loadingData, setLoadingData] = useState(false);
  const [conflictingMeeting, setConflictingMeeting] = useState("");

  const localizer = momentLocalizer(moment);
  const {
    revertCallState,
    personSpokeTo,
    callSid,
    getCallLeadId,
    requireNotes,
    performedNoteAction,
    setIsEnteringRequiredFields,
    handleLeadCardRefresh,
  } = useContext(CallContext);

  const { selectedTabDialQueue } = useContext(LeadCardContext);

  const isThisACallback = ["Schedule Callback", "Scheduled Callback", "Schedule Call Back"].includes(eventType);

  const createEventSchema = Yup.object().shape({
    id: Yup.string(),
    time: Yup.string(),
    specific_time: Yup.string().when("time", {
      is: "Specific Time",
      then: Yup.string()
        .required("Required")
        .test("start time is in the future", "That start time is in the past", function (value) {
          return moment(value).isAfter(moment());
        }),
    }),

    general_time: Yup.string(),
    timezone: Yup.string(),
    day: Yup.string(),
    week_start: Yup.string(),
    week_end: Yup.string(),
    month: Yup.string(),
    anytime_after: Yup.string().when("time", {
      is: "General Time",
      then: Yup.string().when("anytime_before", {
        is: "" || undefined,
        then: Yup.string().required("This field is required."),
      }),
    }),
    anytime_before: Yup.string().when("time", {
      is: "General Time",
      then: Yup.string()
        .when("anytime_after", {
          is: "" || undefined,
          then: Yup.string().required(),
        })
        .when("anytime_after", {
          is: (value) => value?.length > 0 ?? false,
          then: Yup.string().test("anytime_before", "Anytime before must come after anytime after.", function (value) {
            return moment(value).isAfter(this.parent.anytime_after);
          }),
        }),
    }),
    primary_email: Yup.string().when("time", {
      is: "Specific Time",
      then: isThisACallback
        ? Yup.string().nullable().notRequired()
        : Yup.string().required("Primary email is required").email("Please enter a valid email address").trim(),
      otherwise: Yup.string().nullable().notRequired(),
    }),
    calendar_invite: Yup.boolean(),
    guest_list: Yup.array().when("time", {
      is: "Specific Time",
      then: Yup.array()
        .notRequired()
        .nullable()
        .of(
          Yup.string().test("is-email", "Please enter a valid guest list email.", (value) => {
            const onCalendarInviteStep = dispositionStep === 3;

            const calendarInviteEnabled = formikRef?.current?.values?.calendar_invite;

            if (!onCalendarInviteStep || !calendarInviteEnabled) {
              return true;
            } else {
              return validateIfEmailAddress(value ?? "");
            }
          }),
        ),
      otherwise: Yup.array().notRequired().nullable(),
    }),

    event_title: Yup.string().when(["time", "calendar_invite"], {
      is: (time, calendar_invite) => Boolean(calendar_invite) && time === "Specific Time",
      then: Yup.string().required("Event title is required"),
      otherwise: Yup.string().notRequired().nullable(),
    }),
    event_description: Yup.string(),
    joinMe_id: Yup.string(),
    available_reps_for_booking: Yup.array().notRequired().nullable().of(Yup.string()),
    opsIQ_suggested_rep: Yup.string().notRequired().nullable(),
    selected_rep:
      // if it is AUTO This will not be used or required
      // if it is manual this is required
      Yup.string().test("Auto or Manual", "Please select a rep", (value) => {
        if (dataOrgFutureBookingSettings?.fetchOrgFutureBookingSettings?.transfer_type === "Manual")
          return value !== undefined && value !== null && !!value;
        // we don't need to check for a value if it is auto
        else return true;
      }),
  });

  const hasExtraRequiredDemoFields =
    eventType.includes("Demo") &&
    loggedInUser().organization_id &&
    [
      // "91f1b9c2-009b-48f9-93e5-b044788c1aa5", // Drew Test
      "35372a5d-8395-4f2e-974f-4ac1d0216a3e", // FieldPulse
      "ece14be5-953f-4213-b102-9c409ccbba14", // FieldPulse Sandbox
      "fb969838-92b5-4acf-ab03-38ec19a71140", // Staging Test Client
    ].includes(loggedInUser().organization_id ?? "return-false");

  const [createScheduledEvent, { loading: loadingCreate, error: errorCreate }] = useMutation(CREATE_SCHEDULED_EVENT, {
    async onCompleted({ createScheduledEvent }) {
      console.log("createScheduledEvent: ", createScheduledEvent);
      if (!createScheduledEvent) {
        errorToast("Error createScheduledEvent. Something went wrong.");
        return;
      }

      // This mutation implicitly logs a disposition
      // Make sure to update dispositionLogged if call is still going on
      // If the call has ended, revert back to dashboard

      setOldScheduledID(createScheduledEvent.id);

      const callLeadId = getCallLeadId();
      const associateChildrenIds = new Set(
        createScheduledEvent?.lead_intent?.lead?.associate_children.map((child: { id: string }) => child.id),
      );
      const isSameCallLead =
        callLeadId === createScheduledEvent.lead_intent.lead.id || associateChildrenIds.has(callLeadId);
      if (hasExtraRequiredDemoFields) {
        setIsEnteringRequiredFields(true);
      }

      if (!currentCallState().onCall && isSameCallLead && !hasExtraRequiredDemoFields) {
        revertCallState();
      } else {
        currentCallState({
          ...currentCallState(),
          dispositionLogged: isSameCallLead ? true : currentCallState().dispositionLogged,
        }); // keep disposition logged to what it was if the callLeadId has changed.
        handleLeadCardRefresh && handleLeadCardRefresh();
      }

      if (!!createScheduledEvent?.lead?.last_lead_activity) {
        customToast(
          <CustomToastComponent
            completed_image={createScheduledEvent.lead.last_lead_activity.related_disposition.company_image}
            completed_text={createScheduledEvent.lead.last_lead_activity.related_disposition.completed_text}
            completed_tip={createScheduledEvent.lead.last_lead_activity.related_disposition.completed_tip}
          />,
          { autoClose: 10000 },
        );
      } else {
        successToast(`Successfully scheduled event! Metrics are being updated.`, { autoClose: 10000 });
      }
    },
    onError({ message }) {
      if (!!message && message.includes("Event cannot be scheduled: Schedule Conflict. id:")) {
        const id = message.split("Event cannot be scheduled: Schedule Conflict. id:").pop();
        setConflictingMeeting(!!id ? id : "");
        appToast("Event cannot be scheduled: Schedule Conflict.");
      } else {
        appToast(message);
      }
      Sentry.captureEvent({
        message: `createScheduleEvent GraphQL Error: ${message}`,
      });
      // * NOTE: If error, revert from demo required fields to finish scheduling first
      if (hasExtraRequiredDemoFields) {
        revertDemoRequiredFields();
      }
      console.log("Error in createScheduledEvent: ", message);
    },
    refetchQueries: ["fetchMySchedule", "fetchCustomQueueLeads", "fetchNextDial", "fetchFilteredLeadsInQueueV2"],
  });

  const [createGeneralTimeScheduledEvent, { loading: loadingCreateGeneral, error: errorCreateGeneral }] = useMutation(
    CREATE_GENERAL_TIME_SCHEDULED_EVENT,
    {
      async onCompleted({ createGeneralTimeScheduledEvent }) {
        console.log("createGeneralTimeScheduledEvent: ", createGeneralTimeScheduledEvent);
        if (!createGeneralTimeScheduledEvent) {
          appToast("Error scheduling to general time!");
          return;
        }

        // This mutation implicitly logs a disposition
        // Make sure to update dispositionLogged if call is still going on
        // If the call has ended, revert back to dashboard
        if (!currentCallState().onCall) {
          revertCallState();
        } else {
          const callLeadId = getCallLeadId();
          currentCallState({
            ...currentCallState(),
            dispositionLogged:
              callLeadId !== createGeneralTimeScheduledEvent.lead.id ? currentCallState().dispositionLogged : true,
          });
        }

        if (!!createGeneralTimeScheduledEvent.lead_activity) {
          customToast(
            <CustomToastComponent
              completed_image={createGeneralTimeScheduledEvent.lead_activity.related_disposition.company_image}
              completed_text={createGeneralTimeScheduledEvent.lead_activity.related_disposition.completed_text}
              completed_tip={createGeneralTimeScheduledEvent.lead_activity.related_disposition.completed_tip}
            />,
            { autoClose: 10000 },
          );
        } else {
          successToast(`Successfully scheduled event! Metrics are being updated.`, { autoClose: 10000 });
        }
      },
      onError({ message }) {
        errorToast(message);
        Sentry.captureEvent({
          message: `createGeneralTimeCallback GraphQL Error: ${message}`,
        });
        console.log("Error in createGeneralTimeScheduledEvent: ", message);
      },
      refetchQueries: ["fetchMySchedule", "fetchCustomQueueLeads", "fetchNextDial", "fetchFilteredLeadsInQueueV2"],
    },
  );

  const { data: dataCal, loading: loadingCal, error: errorCal, refetch: refetchCal } = useQuery(MONTHLY_SCHEDULE, {
    variables: { yearmonth: yearMonth },
    fetchPolicy: "cache-and-network",
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  const { data: dataOrgInfo, loading: orgInfoLoading, error: orgInfoError } = useQuery(GET_GLOBAL_SCHEDULE, {
    fetchPolicy: "cache-and-network",
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  function returnDataCal() {
    if (loadingCal) return <SkeletonBlock height={"100%"} width={"100%"} borderRadius={8} />;
    if (errorCal) return <AppErrorText>Error loading organization data!</AppErrorText>;
    return dataCal.fetchScheduleItemsMonth
      ?.map((item: any) => ({
        start: moment(item, "YYYY-MM-DD").format(),
        end: moment(item, "YYYY-MM-DD").add(5, "minutes").format(),
        title: "+",
        color: theme.PRIMARY500,
      }))
      ?.filter((item: any) => moment(item.start).unix() > moment().subtract(1, "day").unix());
  }

  const sortedScheduleItemsMonth = useMemo(() => {
    if (dataCal && dataCal.fetchScheduleItemsMonth) {
      return dataCal.fetchScheduleItemsMonth
        ?.map((i: string) => moment(i, "YYYY-MM-DD").format("YYYY-MM-DD"))
        ?.sort((a: string, b: string) => moment(a, "YYYY-MM-DD").unix() - moment(b, "YYYY-MM-DD").unix());
    }
    return [];
  }, [dataCal && dataCal.fetchScheduleItemsMonth]);

  const monthlyEvents = returnDataCal();

  const { data: dataOrg, loading: loadingOrg, error: errorOrg } = useQuery(FETCH_ORGANIZATION, {
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  const { data: user, loading: loadingUser, error: errorUser } = useQuery(FETCH_USER_TIMEZONE, {
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  const [createJoinMe, { loading: loadingJoinMe, error: errorJoinMe }] = useMutation(CREATE_JOIN_ME, {
    async onCompleted({ createJoinMe }) {
      if (!createJoinMe) {
        errorToast("Error creating JoinMe. Please try again.");
        return;
      }
      setJoinMeMeeting(createJoinMe);
      successToast("JoinMe created");
    },
    onError({ message }) {
      errorToast(message);
      Sentry.captureEvent({
        message: `createJoinMe GraphQL Error: ${message}`,
      });
      console.log("Error in createJoinMe: ", message);
    },
  });

  const [removeJoinMeItem, { loading: loadingRemoveJoinMeItem, error: errorRemoveJoinMeItem }] = useMutation(
    REMOVE_JOIN_ME_ITEM,
    {
      async onCompleted({ removeJoinMeItem }) {
        if (!removeJoinMeItem) {
          errorToast("Error creating JoinMe. Please try again.");
          return;
        }
        setJoinMeMeeting({});
        appToast("JoinMe removed");
      },
      onError({ message }) {
        errorToast(message);
        Sentry.captureEvent({
          message: `removeJoinMeItem GraphQL Error: ${message}`,
        });
        console.log("Error in removeJoinMeItem: ", message);
      },
    },
  );

  /**
   * Returns the current user's future booking transfer type (Manual or Auto)
   * If the user's organization has rep_level_call_transfer setting set to true,
   * Then it will return the user's booking transfer type on the user object
   * Otherwise, it will return the organization's future booking transfer type
   */
  const bookingTransferType = useMemo(() => {
    if (!loadingOrgFutureBookingSettings) {
      return loggedInUser().organization?.rep_level_call_transfer
        ? loggedInUser().booking_transfer_type
        : dataOrgFutureBookingSettings?.fetchOrgFutureBookingSetting?.transfer_type;
    }
    return "Manual";
  }, [dataOrgFutureBookingSettings, loadingOrgFutureBookingSettings]);

  /**
   *
   * We can't use the cached userZoomStatus and userGoogleStatus because the selected rep for the event can change if it is sdr and automatic or manual
   *
   * So we need to do a fresh fetch here.
   */
  const [
    checkUserZoomStatus,
    { data: userZoomStatus, loading: loadingCheckUserZoomStatus, error: errorCheckUserZoomStatus },
  ] = useLazyQuery<CheckUserZoomStatusExpectedResponse>(CHECK_USER_ZOOM_STATUS, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      console.log("Zoom status fetched:", data);
    },
    onError: ({ message, name }) => {
      console.error(`Error in ${name}:`, message);
      errorToast(`Failed to check Zoom status: ${message}`);
    },
  });

  const [
    checkUserGoogleStatus,
    { data: userGoogleStatus, loading: loadingCheckUserGoogleStatus, error: errorCheckUserGoogleStatus },
  ] = useLazyQuery<CheckUserGoogleStatusExpectedResponse>(CHECK_USER_GOOGLE_STATUS, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      console.error(`Error in ${name}:`, message);
      errorToast("Failed to check Google Meet organization status");
    },
  });

  useEffect(() => {
    if (dispositionStep === 3 && !isThisACallback) {
      let userIdToCheck =
        bookingTransferType === "Manual"
          ? // selected rep or default
            formikRef?.current?.values?.selected_rep ?? formikRef?.current?.values?.opsiq_suggested_rep
          : formikRef?.current?.values?.opsiq_suggested_rep;

      if (!userIdToCheck && loggedInUser().role === "AE") {
        // this is a bug in the BE the BE should always return the AE's id as the opsiq_suggested_rep for the event
        // this is because the AE can transfer to a different AE rep based on the org transfer settings so it won't ALWAYS be themselves
        // if this setting is on, the BE will suggest a different rep for the event to the AE and it will be the opsiq_suggested_rep
        // the default should always be the AE's id
        userIdToCheck = loggedInUser().id;
      }

      if (!userIdToCheck) {
        console.log("Error. Unable to find user id to check integration status for SDR", {
          bookingTransferType,
          opsiq_suggested_rep: formikRef?.current?.values?.opsiq_suggested_rep,
          selected_rep: formikRef?.current?.values?.selected_rep,
          user: loggedInUser().id,
        });
        return;
      }

      checkUserZoomStatus({ variables: { user_id: userIdToCheck } });
      checkUserGoogleStatus({ variables: { user_id: userIdToCheck } });
    }
  }, [dispositionStep, isThisACallback, bookingTransferType]);
  useEffect(() => {
    // ran into issue where on load of disposition step 3 the formik form was not being re validated (check for guest list errors)
    if (dispositionStep === 3 && formikRef) formikRef?.current?.validateForm();
  }, [dispositionStep]);

  /**
   *
   * @returns the formated event title based on the event type to be used in the UI for things like the calendar event titlea
   *
   */
  const formatedEventTitle: "Call" | "Decision Call" | "Demo" | "N/A" = useMemo(() => {
    // helper variable but it is still based on the eventType
    if (isThisACallback) {
      return "Call";
    }
    if (eventType.includes("Decision Call")) {
      return "Decision Call";
    }
    if (eventType.includes("Demo")) {
      return "Demo";
    }
    return "N/A";
  }, [isThisACallback, eventType]);

  /**
   *
   * @returns the formated event duration based on the unformated event type
   *
   */
  const formatedEventDuration: number | undefined = useMemo(() => {
    // helper variable but it is still based on the eventType
    if (isThisACallback) {
      return Number(dataOrgInfo?.fetchOrganization?.callback_duration || 15);
    }
    if (eventType.includes("Decision Call")) {
      return Number(dataOrgInfo?.fetchOrganization?.decision_call_duration || 60);
    }
    if (eventType.includes("Demo")) {
      return Number(dataOrgInfo?.fetchOrganization?.demo_duration || 60);
    }
  }, [isThisACallback, eventType, dataOrgInfo]);

  /**
   *
   * @returns the default event title based on the formated event title and lead full name for the calendar event title
   *
   */
  const generateDefaultEventTitle = (
    formatedEventTitle: "Call" | "Decision Call" | "Demo" | "N/A",
    lead_full_name?: string,
  ) => {
    // Late Night HOT FIX For FieldPulse
    // should remove ASAP
    const testOrg = "91f1b9c2-009b-48f9-93e5-b044788c1aa5";
    const fieldPulseOrg = "35372a5d-8395-4f2e-974f-4ac1d0216a3e";

    const UnuqiueDemoTileOrgs = [testOrg, fieldPulseOrg];

    if (UnuqiueDemoTileOrgs.includes(loggedInUser().organization_id || "")) {
      switch (formatedEventTitle) {
        case "Demo":
          return `${loggedInUser().organization?.name} - Live Demo`;
        case "Decision Call":
          return `${loggedInUser().organization?.name} - Meeting`;
        default:
          return `${formatedEventTitle} ${lead_full_name ? `with ${lead_full_name}` : ""}`;
      }
    }

    // default for all other cases of events types and for other orgs
    return `${formatedEventTitle} ${lead_full_name ? `with ${lead_full_name}` : ""}`;
  };
  const loadingSwitches =
    loadingCheckUserZoomStatus ||
    loadingCheckUserGoogleStatus ||
    zoomIntegration === undefined ||
    googleMeetIntegration === undefined ||
    loadingOrg ||
    loadingUser ||
    loadingCal ||
    loadingJoinMe ||
    loadingRemoveJoinMeItem ||
    orgInfoLoading ||
    loadingCreate;

  const selectedEventRepHasZoomEnabled = useMemo(() => {
    if (userZoomStatus && userZoomStatus.checkUserZoomStatus) {
      return userZoomStatus.checkUserZoomStatus.zoom_status === "active";
    }
    return false;
  }, [userZoomStatus]);

  const selectedEventRepHasGoogleEnabled = useMemo(() => {
    if (userGoogleStatus && userGoogleStatus.checkUserGoogleStatus) {
      return userGoogleStatus.checkUserGoogleStatus.google_status === "active";
    }
    return false;
  }, [userGoogleStatus]);

  if (
    loadingOrg ||
    loadingUser ||
    loadingCal ||
    loadingJoinMe ||
    loadingRemoveJoinMeItem ||
    orgInfoLoading ||
    loadingCreate
  )
    return <SkeletonBlock width={"100%"} height={500} borderRadius={8} />;

  if (
    errorOrg ||
    errorUser ||
    errorCal ||
    errorJoinMe ||
    errorRemoveJoinMeItem ||
    orgInfoError ||
    errorCheckUserZoomStatus
  )
    return <AppErrorText>Error loading data.</AppErrorText>;

  return (
    <Formik
      validateOnMount
      innerRef={formikRef}
      enableReinitialize //enabled to allow updating "edit lead details modal" to update values in this form as well
      initialValues={{
        id: selectedDataID,
        primary_email: lead_primary_email ? lead_primary_email : "",
        time: "Specific Time",
        specific_time: "",
        general_time: "",
        timezone:
          user?.fetchUser?.timezone ||
          (dataOrg && dataOrg.fetchOrganization && dataOrg.fetchOrganization.timezone) ||
          "America/New_York",
        day: "",
        week_start: "",
        week_end: "",
        month: "",
        anytime_after: "",
        anytime_before: "",
        guest_list: !!lead_primary_email ? [lead_primary_email] : [],
        calendar_invite: loggedInUser()?.timekit_type !== "Local" ? true : false,
        meeting_type: "PHONE",
        event_title: generateDefaultEventTitle(formatedEventTitle, props.lead_full_name),
        event_description: "",
        joinMe_id: "",
        edit_event: false,

        // rep id
        available_reps_for_booking: [],
        opsiq_suggested_rep: !!user_id ? user_id : undefined,
        selected_rep: undefined,
      }}
      validationSchema={createEventSchema}
      onSubmit={async (values) => {
        if (values.time === "Specific Time") {
          // * NOTE: Optimistically move to Demo Required Fields screen while event is being scheduled
          // * this is to avoid the long loading time for event scheduling disposition
          if (hasExtraRequiredDemoFields) {
            startDemoRequiredFields();
          }
          await createScheduledEvent({
            variables: {
              lead_id: lead_id,
              start_time: values.specific_time,
              type: eventType === "schedule demo" ? "Demo" : "ScheduledCallBack",
              person_spoke_to: !!personSpokeTo ? personSpokeTo : undefined,
              intent_id: props.intent_id,
              primary_email: values.primary_email,
              /**
               * call should be set to true if event is getting created via a call.
               */
              call: !fromNonCallActivity,
              disposition_id: selectedDisposition,
              action: eventType,
              user_id:
                /**
                 * manual = selected rep. if for some reason we have a bug and the selected rep is undefined, fallback to the suggested rep
                 * auto = suggested rep from the timeslot or fallback to the user_id (set in initial values)
                 *
                 */
                bookingTransferType === "Manual"
                  ? values.selected_rep ?? values.opsiq_suggested_rep
                  : values.opsiq_suggested_rep,
              /**
               * JoinME event fields
               */

              calendarInvite: values.calendar_invite && !isThisACallback,
              event_title: values.event_title,
              event_description: values.event_description,
              guest_list: !!values.guest_list ? values.guest_list.filter((g) => !!g)?.map((g) => g.trim()) : [],
              joinMe_id: !!joinMeMeeting.id ? joinMeMeeting.id : undefined,
              meeting_type: values.meeting_type || "PHONE",
              old_schedule_item_id: !!oldScheduledID ? oldScheduledID : undefined,
              from_custom_call_queue: selectedTabDialQueue === "custom",
              timezone: !!values.timezone ? values.timezone : undefined,
              call_sid: callSid,
              disposition_reason:
                logDispositionReason && dispositionRequiresReason ? selectedDispositionReason : undefined,
            },
          });
        }
        if (values.time === "General Time") {
          await createGeneralTimeScheduledEvent({
            variables: {
              lead_id: lead_id,
              ...(!!values.day && { day: values.day }),
              ...(!!values.week_start && { week_start: values.week_start }),
              ...(!!values.week_end && { week_end: values.week_end }),
              ...(!!values.month && { month: values.month }),
              ...(!!values.anytime_after && { anytime_after: values.anytime_after }),
              ...(!!values.anytime_before && { anytime_before: values.anytime_before }),
              timezone: values.timezone,
              person_spoke_to: !!personSpokeTo ? personSpokeTo : undefined,
              intent_id: props.intent_id,
              /**
               * call should be set to true if event is getting created via a call.
               */
              call: !fromNonCallActivity,
              disposition_id: selectedDisposition,
              action: eventType,
              from_custom_call_queue: selectedTabDialQueue === "custom",
              call_sid: callSid,
              disposition_reason:
                logDispositionReason && dispositionRequiresReason ? selectedDispositionReason : undefined,
            },
          });
        }
      }}
    >
      {({
        submitForm,
        isSubmitting,
        values,
        setFieldValue,
        validateForm,
        dirty,
        isValid,
        errors,
        touched,
      }: FormikProps<MyFormikProps>) => {
        console.log("values: ", values);
        console.log("errors: ", errors);
        console.log("dirty: ", dirty);

        const showRepCard =
          !isSubmitting &&
          dispositionStep === 1 &&
          values.specific_time &&
          bookingTransferType === "Auto" &&
          values.opsiq_suggested_rep &&
          loggedInUser().role === "SDR";

        return (
          <>
            <FormWrapper>
              {requireNotes && !performedNoteAction && <PhoenixStyledTooltip id="missing-required-notes" />}

              {/* choose time step */}
              {dispositionStep === 1 && (
                <ScheduleEventDiv>
                  {/* This is step 1 */}
                  <ScheduleTimeSelectComponentV3
                    user_id={user_id}
                    lead_id={lead_id}
                    isInCallRoute
                    callOptions={eventType}
                    errors={errors}
                    values={values}
                    setFieldValue={setFieldValue}
                    selectedDataTime={selectedDataTime}
                    dateValue={dateValue}
                    setDateValue={setDateValue}
                    yearMonth={yearMonth}
                    setYearMonth={setYearMonth}
                    specificOptionStep={specificOptionStep}
                    setSpecificOptionStep={setSpecificOptionStep}
                    toggleCalendar={() => {
                      setShowCalendar(!showCalendar);
                    }}
                    closeCalendar={() => setShowCalendar(false)}
                    disableGeneralTime={!isThisACallback}
                    events={!!monthlyEvents.length ? monthlyEvents : []}
                    setAvailableRepsForBooking={(repIdArr) => {
                      setFieldValue("available_reps_for_booking", repIdArr);
                    }}
                    setOpsiqSuggestedRep={(repId) => {
                      setFieldValue("opsiq_suggested_rep", repId);
                    }}
                  />
                </ScheduleEventDiv>
              )}
              {isSubmitting ? (
                <SkeletonBlock height={"100%"} width={"100%"} borderRadius={8} />
              ) : (
                <ContainerDiv paddingBottom={showRepCard ? 70 : 0}>
                  {dispositionStep === 1 && (
                    <>
                      {!(values.time === "General Time" || isThisACallback) && (
                        <PrimaryEmailDiv>
                          <PopupInputLabel>
                            Lead Primary Email<span style={{ color: "red" }}>*</span>
                          </PopupInputLabel>
                          <PhoenixInputField name="primary_email" />
                        </PrimaryEmailDiv>
                      )}

                      {values.time === "Specific Time" && !isThisACallback ? (
                        <SubmitButton
                          fromNonCallActivity={fromNonCallActivity}
                          variant="brand"
                          buttonType="secondary"
                          disabled={!isValid}
                          onClick={() => {
                            const sdr = loggedInUser().role === "SDR";
                            !!!values.guest_list[0] &&
                              values.guest_list.length <= 1 &&
                              setFieldValue("guest_list", !!values.primary_email ? [values.primary_email] : []);
                            if (sdr) {
                              // MANUAL SDR (next go to select rep for booking)
                              if (bookingTransferType === "Manual") setDispositionStep(2);
                              // AUTO SDR
                              // skip select rep for booking
                              else setDispositionStep(3);

                              // AE
                            } else {
                              // NOTE: if there ever a new feature that allows AE's to book future demos for other reps, this will need to change to 2
                              setDispositionStep(3);
                            }
                          }}
                        >
                          Next
                        </SubmitButton>
                      ) : (
                        <SubmitButton
                          disabled={!isValid || (requireNotes && !performedNoteAction)}
                          variant="brand"
                          buttonType="secondary"
                          onClick={submitForm}
                          fromNonCallActivity={fromNonCallActivity}
                          data-for={requireNotes && !performedNoteAction ? "missing-required-notes" : ""}
                          data-tip={"Please add a note before proceeding."}
                        >
                          {switchLabel(eventType, fromNonCallActivity)}
                        </SubmitButton>
                      )}
                    </>
                  )}
                  {/* select rep step */}
                  {dispositionStep === 2 && (
                    <SelectRepForBooking
                      selectedRep={values.selected_rep}
                      availableRepsForBooking={values.available_reps_for_booking}
                      handleSelectRep={(rep_id: string) => {
                        setFieldValue("selected_rep", rep_id);
                      }}
                      handleNext={() => setDispositionStep(3)}
                    />
                  )}

                  {/* calendar invite step */}
                  {dispositionStep === 3 && !isThisACallback && (
                    <>
                      {zoomIntegration || googleMeetIntegration ? (
                        <CalendarInviteStep
                          values={values}
                          setFieldValue={setFieldValue}
                          validateForm={validateForm}
                          eventType={eventType}
                          loadingSwitches={loadingSwitches}
                          formatedEventDuration={formatedEventDuration}
                          timezone_list_items={timezone_list_items}
                          zoomIntegration={zoomIntegration}
                          googleMeetIntegration={googleMeetIntegration}
                          setDispositionStep={setDispositionStep}
                          loadingJoinMe={loadingJoinMe}
                          joinMeMeeting={joinMeMeeting}
                          createJoinMe={createJoinMe}
                          removeJoinMeItem={removeJoinMeItem}
                          fromNonCallActivity={!!fromNonCallActivity}
                          loadingRemoveJoinMeItem={loadingRemoveJoinMeItem}
                          formatedEventTitle={formatedEventTitle}
                          isValid={isValid}
                          loadingCreate={loadingCreate}
                          zoomIsEnabledOnOrg={zoomIsEnabledOnOrg}
                          googleMeetIsEnabledOnOrg={googleMeetIsEnabledOnOrg}
                          loadingCheckUserZoomStatus={loadingCheckUserZoomStatus}
                          selectedEventRepHasZoomEnabled={selectedEventRepHasZoomEnabled}
                          selectedEventRepHasGoogleEnabled={selectedEventRepHasGoogleEnabled}
                          submitForm={submitForm}
                          requireNotes={requireNotes}
                          performedNoteAction={performedNoteAction}
                        />
                      ) : (
                        <>
                          <HeaderContainer>
                            <AppText fontSize={16}>Calendar Invite</AppText>
                          </HeaderContainer>
                          <SwitchDiv>
                            <PhoenixStyledTooltip id="allow-cal-invite-tooltip" place="top" fontSize={10} padding={8} />
                            <SwitchText>Enable Calendar Invite</SwitchText>
                            <div
                              style={{ paddingTop: "6px" }}
                              data-for={loggedInUser()?.timekit_type === "Local" && "allow-cal-invite-tooltip"}
                              data-tip="Calendar must be synced to send an invite."
                            >
                              <Switch
                                onChange={(checked) => {
                                  setFieldValue("calendar_invite", checked);
                                  validateForm();
                                }}
                                checked={values.calendar_invite}
                                onColor={theme.PRIMARY500}
                                offColor={theme.NEUTRAL200}
                                height={16}
                                width={32}
                                handleDiameter={12}
                                checkedIcon={false}
                                uncheckedIcon={false}
                                activeBoxShadow="0 0 3px #000"
                                disabled={loggedInUser()?.timekit_type === "Local"}
                              />
                            </div>
                          </SwitchDiv>
                          {values.calendar_invite ? (
                            <div style={{ margin: fromNonCallActivity ? "25px" : "0px" }}>
                              <PopupInputLabel style={{ fontWeight: 600 }}>Event Date</PopupInputLabel>
                              <DateText>
                                {moment(values?.specific_time).tz(values?.timezone)?.format("dddd, MMMM Do")}
                                &nbsp;
                                {moment(values?.specific_time).tz(values?.timezone)?.format("h:mm A")} -&nbsp;
                                {moment(values?.specific_time)
                                  .tz(values?.timezone)
                                  .add(formatedEventDuration, "minutes")
                                  .format("h:mm A")}
                                &nbsp; (
                                {
                                  timezone_list_items
                                    ?.slice()
                                    ?.filter((item: any) => item?.value === values?.timezone)[0]?.label
                                }
                                )
                              </DateText>
                              <PopupInputLabel>
                                Event Title<span style={{ color: theme.ATTENTION700 }}>*</span>
                              </PopupInputLabel>
                              <MeetingInput id="event-title" name="event_title" style={{ marginBottom: "0px" }} />
                              <PopupInputLabel>Guests</PopupInputLabel>
                              <FieldArray
                                name="guest_list"
                                render={({ push, insert, remove }) => (
                                  <div>
                                    {values.guest_list &&
                                      values.guest_list.length > 0 &&
                                      values.guest_list?.map((option, index) => (
                                        <DropdownOptionContainer key={index}>
                                          <DropdownOption
                                            name={`guest_list[${index}]`}
                                            emailIsValid={validateIfEmailAddress(values.guest_list[index])}
                                          />

                                          <AppIconButton>
                                            <FiTrash2 onClick={() => remove(index)} />
                                          </AppIconButton>
                                        </DropdownOptionContainer>
                                      ))}
                                    <LeftDiv>
                                      <NewAppButton
                                        size={"sm"}
                                        variant={"secondary"}
                                        onClick={() => {
                                          push("");
                                        }}
                                      >
                                        Add guest
                                      </NewAppButton>
                                    </LeftDiv>
                                  </div>
                                )}
                              />
                              {loggedInUser().joinMe_connected && (
                                <>
                                  <PopupInputLabel>JoinMe URL</PopupInputLabel>
                                  {!!joinMeMeeting.id ? (
                                    <>
                                      <LinkHeader>Viewer Link</LinkHeader>
                                      <DropdownOptionContainer>
                                        <LinkGridContainer>
                                          <LinkDiv>
                                            <Link>{joinMeMeeting.viewer_url ?? "No link available."}</Link>
                                          </LinkDiv>
                                          <RightText
                                            onClick={() => {
                                              navigator?.clipboard?.writeText(joinMeMeeting.viewer_url);
                                            }}
                                          >
                                            Copy
                                          </RightText>
                                        </LinkGridContainer>
                                        {loadingRemoveJoinMeItem ? (
                                          <div style={{ maxWidth: "60px" }}>
                                            <SkeletonBlock height={"100%"} width={"100%"} borderRadius={8} />
                                          </div>
                                        ) : (
                                          <img
                                            src={iconTrashNew}
                                            alt="Delete"
                                            style={{ cursor: "pointer", marginTop: "11px" }}
                                            onClick={async () =>
                                              await removeJoinMeItem({ variables: { joinME_id: joinMeMeeting.id } })
                                            }
                                          />
                                        )}
                                      </DropdownOptionContainer>
                                    </>
                                  ) : loadingJoinMe ? (
                                    <div style={{ maxHeight: "60px" }}>
                                      <SkeletonBlock height={32} width={"100%"} borderRadius={8} />
                                    </div>
                                  ) : (
                                    <JoinMeButton
                                      width={257}
                                      fontsize={13}
                                      onClick={() =>
                                        createJoinMe({
                                          variables: {
                                            start: moment(values.specific_time).tz(values.timezone).toDate(),
                                            end: moment(values.specific_time)
                                              .tz(values.timezone)
                                              .add(formatedEventDuration, "minutes")
                                              .toDate(),
                                          },
                                        })
                                      }
                                    >
                                      <>
                                        <img src={iconDesktop} height={32} />{" "}
                                        <p>Create JoinMe {formatedEventTitle} Link</p>
                                      </>
                                    </JoinMeButton>
                                  )}
                                </>
                              )}

                              <PopupInputLabel>Event Description</PopupInputLabel>
                              <MeetingDescriptionInput id="event-description" name="event_description" />
                            </div>
                          ) : (
                            <div style={{ height: "50vh" }}></div>
                          )}

                          <SubmitButton
                            disabled={!isValid || loadingCreate || (requireNotes && !performedNoteAction)}
                            variant="brand"
                            buttonType="secondary"
                            type="submit"
                            onClick={() => {
                              submitForm();
                              if (values.calendar_invite) {
                                setDispositionStep(4);
                              }
                            }}
                            fromNonCallActivity={fromNonCallActivity}
                            data-for={requireNotes && !performedNoteAction ? "missing-required-notes" : ""}
                            data-tip={"Please add a note before proceeding."}
                          >
                            {switchLabel(eventType, fromNonCallActivity)}
                          </SubmitButton>
                        </>
                      )}
                    </>
                  )}

                  {dispositionStep === 4 && (
                    <>
                      {!!oldScheduledID ? (
                        <>
                          <CalendarInviteSuccessDiv>
                            <SwitchText>Calendar Invite Sent!</SwitchText>
                            <img src={calendarInviteSent} height="168px" alt="Calendar invite sent" />
                          </CalendarInviteSuccessDiv>
                          <SubmitButton
                            buttonType="secondary"
                            variant="brand"
                            fromNonCallActivity={fromNonCallActivity}
                            onClick={() => {
                              setDispositionStep(5);
                              setFieldValue("edit_event", true);
                            }}
                          >
                            MAKE A CHANGE
                          </SubmitButton>
                        </>
                      ) : (
                        <>
                          {!!conflictingMeeting ? (
                            <div style={{ minHeight: "50vh" }}>
                              <ConflictingEventDiv>
                                <AppText
                                  style={{
                                    fontWeight: 600,
                                    fontSize: "14px",
                                    lineHeight: "21px",
                                    marginBottom: "8px",
                                  }}
                                >
                                  Schedule Conflict
                                </AppText>
                                <AppText>
                                  You already have a scheduled event at that time. Please reschedule that event or
                                  select another date/time for this event.
                                </AppText>
                                <RescheduleButton
                                  onClick={() => {
                                    setDispositionStep(5);
                                    setFieldValue("edit_event", true);
                                    setConflictingMeeting("");
                                  }}
                                >
                                  Go Back
                                </RescheduleButton>
                                <a href={`/manage-schedule/${conflictingMeeting}`} target="_blank">
                                  <RescheduleButton variant={"primary"} style={{ width: "141px" }}>
                                    Reschedule Prior Event
                                  </RescheduleButton>
                                </a>
                              </ConflictingEventDiv>
                            </div>
                          ) : (
                            <>
                              <CalendarInviteSuccessDiv>
                                <SwitchText>An error occurred</SwitchText>
                              </CalendarInviteSuccessDiv>
                              <SubmitButton
                                buttonType="secondary"
                                variant="danger-outline"
                                fromNonCallActivity={fromNonCallActivity}
                                onClick={() => {
                                  setDispositionStep(5);
                                  setFieldValue("edit_event", true);
                                }}
                              >
                                GO BACK AND TRY AGAIN
                              </SubmitButton>
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                  {fromNonCallActivity && (
                    <SubmitButton
                      buttonType="secondary"
                      variant="danger-outline"
                      fromNonCallActivity={fromNonCallActivity}
                      onClick={closeModal}
                    >
                      Cancel
                    </SubmitButton>
                  )}
                </ContainerDiv>
              )}
              {showCalendar && (
                <CalendarDiv fromNonCallActivity={fromNonCallActivity}>
                  <Calendar
                    selectable={true}
                    localizer={localizer}
                    events={!!monthlyEvents.length ? monthlyEvents : []}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: 300, width: 400 }}
                    views={["month"]}
                    defaultView={"month"}
                    defaultDate={
                      dataCal && dataCal.fetchScheduleItemsMonth
                        ? moment(sortedScheduleItemsMonth[Math.round(sortedScheduleItemsMonth.length / 2)]).toDate()
                        : undefined
                    }
                    onSelectEvent={({ start, end }) => {
                      console.log("cal: ", start, end);
                      setYearMonth(
                        moment(sortedScheduleItemsMonth[Math.round(sortedScheduleItemsMonth.length / 2)]).format(
                          "YYYY-MM",
                        ),
                      );
                      const pos = sortedScheduleItemsMonth
                        ?.map((e: string) => moment(e).format("YYYY-MM-DD"))
                        .indexOf(moment(start).format("YYYY-MM-DD"));
                      setSpecificOptionStep(pos);
                      setDateValue(moment(start).format("YYYY-MM-DD"));
                      setShowCalendar(false);
                    }}
                    onSelectSlot={({ start, end, action }) => console.log("Click: ", moment(start))}
                    eventPropGetter={eventStyleGetter}
                    onNavigate={async (e) => {
                      setLoadingData(true);
                      await refetchCal({ yearmonth: moment(e).format("YYYY-MM") }).then(() => setLoadingData(false));
                    }}
                  />
                  <CloseButton
                    onClick={() => {
                      setShowCalendar(!showCalendar);
                    }}
                  >
                    <IoMdClose size={22} color={theme.PRIMARY500} />
                  </CloseButton>

                  {loadingData && (
                    <CalendarLoadingDiv>
                      <SkeletonBlock height={"100%"} width={"100%"} borderRadius={8} />
                    </CalendarLoadingDiv>
                  )}
                </CalendarDiv>
              )}

              {/* if the booking setting is set to auto show the SDR the rep that was suggested by the system */}
            </FormWrapper>
            {showRepCard && (
              <AbsoluteRepSelection>
                <RepCard user_id={values.opsiq_suggested_rep!} />
              </AbsoluteRepSelection>
            )}
          </>
        );
      }}
    </Formik>
  );
};

const RepCard = ({ user_id }: { user_id: string }) => {
  const FETCH_USER_PREVIEWS = gql`
    query fetchUsersAndPreviews($user_ids: [String!]!) {
      fetchUsersAndPreviews(user_ids: $user_ids) {
        user_id
        user {
          id
          first_name
          last_name
          team {
            id
            name
          }
          profile_image
        }
      }
    }
  `;

  const { data, loading, error } = useQuery(FETCH_USER_PREVIEWS, {
    variables: { user_ids: [user_id] },
    skip: !user_id,
    fetchPolicy: "cache-and-network",
  });

  if (loading) return <SkeletonBlock height={40} width={"100%"} borderRadius={8} />;

  if (error) return <div>{error.message}</div>;

  return (
    <Card id={data?.fetchUsersAndPreviews[0]?.user?.id}>
      <Left>
        <div style={{ display: "flex", alignItems: "center", gap: "16px", marginLeft: "4px" }}>
          <ProfileImageWithText
            height={26}
            width={26}
            textSize={12}
            first_name={data?.fetchUsersAndPreviews[0]?.user?.first_name}
            last_name={data?.fetchUsersAndPreviews[0]?.user?.last_name}
            profile_image={data?.fetchUsersAndPreviews[0]?.user?.profile_image}
          />
          <AppText fontSize={12} fontWeight={600}>
            {data?.fetchUsersAndPreviews[0]?.user?.first_name} {data?.fetchUsersAndPreviews[0]?.user?.last_name}
          </AppText>
        </div>
      </Left>
      <Right>
        <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
          <AppText fontSize={12} fontWeight={500}>
            Team:
            <BoldText> {data?.fetchUsersAndPreviews[0]?.user?.team?.name}</BoldText>
          </AppText>
        </div>
      </Right>
    </Card>
  );
};

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  overflow-y: auto;
`;

const SwitchContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 40px 0px;
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 6px;
`;

const Right = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Card = styled.div`
  width: 100%;
  height: fit-content;
  background: ${theme.LIGHT_BLUE};
  padding: 16px;
  display: grid;
  grid-template-columns: 284px 1fr;
`;

const ContainerDiv = styled.div<{ paddingBottom?: number }>`
  padding-bottom: ${(props) => (props.paddingBottom ? `${props.paddingBottom}px` : "0px")};
  text-align: center;
  width: 100%;
`;

const RescheduleButton = styled(NewAppButton)`
  height: 32px;
  width: 91px;
  border-radius: 2px;
  margin-right: 8px;
  margin-top: 12px;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  text-transform: capitalize;
  letter-spacing: 0.2px;
`;

const ConflictingEventDiv = styled.div`
  width: 100%;

  padding: 24px;

  background: rgba(226, 18, 0, 0.04);
  border: 1px solid ${theme.ATTENTION700};
  border-radius: 8px;
`;

const RightText = styled(AppText)`
  justify-self: end;
  align-self: end;
  color: ${theme.PRIMARY500};
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  opacity: 70%;
  text-decoration: none;
  border-bottom: none;
  :hover {
    opacity: 100%;
    cursor: pointer;
    text-decoration: underline;
  }
  :active {
    text-decoration: none;
  }
  a {
    text-decoration: none;
    color: ${theme.PRIMARY500};
  }
`;

const LinkGridContainer = styled.div`
  display: grid;
  padding: 13px;
  grid-gap: 16px;
  width: 100%;
  max-width: 365px;
  grid-template-columns: 1fr 0.4fr;
  grid-template-rows: 1fr;
  background-color: #f4f4f4;
  border-radius: 4px;
`;

const LinkDiv = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
  justify-content: flex-end;
  min-width: 0;
`;

const Link = styled.div`
  font-weight: 400;
  min-width: 0;
  font-size: 13px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const LinkHeader = styled.p`
  font-weight: 500;
  font-size: 10px;
  margin: 5px;
  color: #5e5e5e;
`;

const CalendarInviteSuccessDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 50vh;
`;

const JoinMeButton = styled(AppButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
  margin: 0px;
  margin-bottom: 10px;
`;

interface ErrorProp {
  emailIsValid?: boolean;
}

const DropdownOption = styled(InputField)<ErrorProp>`
  margin: 0;
  text-align: left;
  max-width: 365px;
  min-width: 365px;
  border: 1px solid ${theme.NEUTRAL200};
  background: ${(props) => (props.emailIsValid ? `url(${checkIcon}) no-repeat scroll 335px 10px` : "none")};
  :focus {
    border: 1px solid ${theme.BLACK_COLOR};
  }
  ::placeholder {
    text-align: left;
  }
`;

const AbsoluteRepSelection = styled.div`
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const DropdownOptionContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  max-width: 405px;
`;

const MeetingDescriptionInput = styled(FormAreaInputField)`
  max-width: 365px;
  min-width: 365px;
  max-height: 130px;
  min-height: 130px;
  border: 1px solid ${theme.NEUTRAL200};
  :focus {
    border: 1px solid ${theme.BLACK_COLOR};
  }
`;

const MeetingInput = styled(InputField)`
  margin: 0px;
  height: 40px;

  max-width: 365px;
  text-align: left;
  border: 1px solid ${theme.NEUTRAL200};
  :focus {
    border: 1px solid ${theme.BLACK_COLOR};
  }
`;

const BoldText = styled.span`
  font-weight: 600;
`;

const DateText = styled(AppText)`
  margin-top: 5px;
  margin-bottom: 16px;
`;

const CalendarLoadingDiv = styled.div`
  position: absolute;
  right: 0px;
  top: 49px;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: calc(100% - 49px);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.1);
  cursor: wait;
  z-index: 5;
`;

const SwitchDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: max-content;
`;

const SwitchText = styled(AppText)`
  font-size: 12px;
  font-weight: 500;
`;

const PopupInputLabel = styled(AppText)`
  font-size: 12px;
  font-weight: 500;
`;

const PrimaryEmailDiv = styled.div`
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ScheduleEventDiv = styled.div`
  margin: 0px;
  width: 100%;
  padding: 10px;
`;

interface LocationProp {
  fromNonCallActivity?: boolean;
}

const SubmitButton = styled(PhoenixAppButton)<LocationProp>`
  margin-top: 48px;
  width: ${(props) => (props.fromNonCallActivity ? "100%" : "262px")};
  height: 47px;
`;

interface CalendarLocationProp {
  fromNonCallActivity?: boolean;
}

const CalendarDiv = styled.div<CalendarLocationProp>`
  position: fixed;
  left: ${(props) => (props.fromNonCallActivity ? "1120px" : "50%")};
  top: 200px;
  border-radius: 3px;
  border: solid 1px ${theme.NEUTRAL100};
  background: ${theme.WHITE_COLOR};
  z-index: 9;
`;

const CloseButton = styled.div`
  position: absolute;
  height: 28px;
  width: 28px;
  right: -14px;
  top: -14px;
  background: ${theme.NEUTRAL100};
  border-radius: 50%;
  cursor: pointer;
  padding: 3px;
  z-index: 6;
`;

const HeaderContainer = styled.div`
  width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
  margin-bottom: 15px;
  margin-top: 40px;
`;

const LeftDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
`;
export { CreateScheduledEventComponentV3 };
